import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Button
} from "@mui/material";


const HandcashTableData = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const apiURL= process.env.REACT_APP_API_URL;
  

  // Fetch orders from the API
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const storedToken = localStorage.getItem('token')
        const response = await axios.get(`${apiURL}/handcashs`,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${storedToken}`,
              }
        });
        if (response.data.success) {
          setOrders(response.data.orders);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Paginate orders
  const paginatedOrders = orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box>
      {/* <Typography variant="h5" gutterBottom>
        Orders
      </Typography>
      <Typography variant="" gutterBottom style={{justifyContent: "right"}}>
        Add Handcash
      </Typography> */}
      <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginBottom={2}
      marginLeft={2}
      marginTop={2}
    >
      {/* Left-aligned title */}
      <Typography variant="h5" gutterBottom>
        Orders
      </Typography>

      {/* Right-aligned link */}
      <Button
        component={Link}
        to="/handcash/addhandcash"
        variant="contained"
        color="primary"
      >
        Add Handcash
      </Button>
    </Box>

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Invoice No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Tel</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Invoice Type</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedOrders.map((order, index) => (
                  <TableRow key={order._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{order.invoiceNo}</TableCell>
                    <TableCell>{order.name}</TableCell>
                    <TableCell>{order.tel}</TableCell>
                    <TableCell>{order.address}</TableCell>
                    <TableCell>{order.invoiceType}</TableCell>
                    <TableCell>{order.totalAmount.toLocaleString()}</TableCell>
                    <TableCell>{new Date(order.date).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={orders.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default HandcashTableData;
