import { React, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import StorageIcon from '@mui/icons-material/Storage';
import PaymentIcon from '@mui/icons-material/Payment';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ApiIcon from '@mui/icons-material/Api';
import PersonIcon from '@mui/icons-material/Person';
import InventoryIcon from '@mui/icons-material/Inventory';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { jwtDecode } from 'jwt-decode';  
import TuneIcon from '@mui/icons-material/Tune';
import axios from 'axios';
import './sidebar.scss';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CardTravelIcon from '@mui/icons-material/CardTravel';
const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation(); 

  const [reminderCount, setReminderCount] = useState(0);

  useEffect(() => {
    // Function to fetch reminder count from the API
    const fetchReminderCount = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/financeloans/payments/reminder`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        setReminderCount(response.data.count); // Set the count based on the API response
      } catch (err) {
        console.error('Failed to fetch reminder count:', err);
      }
    };

    fetchReminderCount();
  }, []); // Run once when the component mounts


  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  // Retrieve and decode token
  const token = localStorage.getItem('token');
  let userRole = '';

  if (token) {
    try {
      const decodedToken = jwtDecode(token);


      userRole = decodedToken.user?.role || decodedToken.role;  // Adjust depending on token structure
    } catch (error) {
      console.error('Token decoding failed:', error);
    }
  }



  // Define roles that can access each item
  const roleAccess = {
    '/': ['manager', 'superadmin',],
    '/users': ['manager', 'superadmin', 'supreme'],
    '/profile': ['superadmin', 'manager', 'admin', 'account', 'sales', 'credit_check'],
    '/forecast': ['superadmin', 'manager', 'account'],
    '/planner': ['superadmin', 'manager', 'account'],
    '/customerloans': ['superadmin', 'manager', 'admin', 'account', 'credit_check'],
    '/customers/cashs/': ['superadmin', 'manager', 'admin', 'account'],
    '/handcash/': ['superadmin', 'manager', 'admin', 'account'],
    '/salesloans': ['sales'],
    '/salescash': ['sales'],
    '/paymenterms': ['superadmin', 'manager', 'admin', 'account'],
    '/search': ['superadmin', 'manager', 'admin', 'account'],
    '/bikemodels': ['superadmin', 'manager', 'admin'],
    '/reminders': ['superadmin', 'manager', 'admin'],
    '/salary': ['superadmin', 'manager', 'admin'],
    '/stocks': ['superadmin', 'admin', 'manager'],
    '/activities': ['superadmin', 'manager'],
    '/roles': ['superadmin', 'supreme', 'manager'],
    '/outlets': ['superadmin', 'supreme'],
  };

  // Determine if the user has access to a given route
  const hasAccess = (path) => {
    const allowedRoles = roleAccess[path];
    // Debugging
    return allowedRoles ? allowedRoles.includes(userRole) : true;
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <span className="logo">
            MMS
          </span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          {hasAccess('/users') && (

            <li className={getActiveClass('/')}>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <DashboardIcon className="icon" />
                <span>Dashboard </span>
              </Link>
            </li>
          )}
          <li className={getActiveClass('/profile')}>
            <Link to="/profile" style={{ textDecoration: 'none' }}>
              <PersonIcon className="icon" />
              <span>User Profile </span>
            </Link>
          </li>
          {hasAccess('/forecast') && (
            <li className={getActiveClass('/forecast')}>
              <Link to="/forecast" style={{ textDecoration: 'none' }}>
                <AutoGraphIcon className="icon" />
                <span>Forecast </span>
              </Link>
            </li>
          )}
          {hasAccess('/planner') && (
            <li className={getActiveClass('/planner')}>
              <Link to="/planner" style={{ textDecoration: 'none' }}>
                <ApiIcon className="icon" />
                <span>Planner </span>
              </Link>
            </li>
          )}

          {hasAccess('/users') && (
            <li className={getActiveClass('/users')}>
              <Link to="/users" style={{ textDecoration: 'none' }}>
                <PeopleAltIcon className="icon" />
                <span>Users </span>
              </Link>
            </li>
          )}
          {hasAccess('/salary') && (
            <li className={getActiveClass('/salary')}>
              <Link to="/salary" style={{ textDecoration: 'none' }}>
                <AccountBalanceWalletIcon className="icon" />
                <span>Salary </span>
                {reminderCount > 0 && (
                  <span className="badge">{reminderCount}</span>
                )}
              </Link>
            </li>
          )}
          {hasAccess('/search') && (
            <li className={getActiveClass('/search')}>
              <Link to="/search" style={{ textDecoration: 'none' }}>
                <FilterAltIcon className="icon" />
                <span>Advance Search </span>
                {reminderCount > 0 && (
                  <span className="badge">{reminderCount}</span>
                )}
              </Link>
            </li>
          )}
          {hasAccess('/customerloans') && (
            <li className={getActiveClass('/customerloans')}>
              <Link to="/customerloans" style={{ textDecoration: 'none' }}>
                <ListAltIcon className="icon" />
                <span>Loans Customer</span>
              </Link>
            </li>
          )}
          {hasAccess('/customers/cashs/') && (
            <li className={getActiveClass('/customers/cashs/')}>
              <Link to="/customers/cashs/" style={{ textDecoration: 'none' }}>
                <StorageIcon className="icon" />
                <span>Third Party Cash </span>
              </Link>
            </li>
          )}
          {hasAccess('/handcash') && (
            <li className={getActiveClass('/customers/cashs/')}>
              <Link to="/handcash" style={{ textDecoration: 'none' }}>
                <CardTravelIcon className="icon" />
                <span>Cash customer </span>
              </Link>
            </li>
          )}
          {hasAccess('/salesloans') && (
            <li className={getActiveClass('/salesloans')}>
              <Link to="/salesloans" style={{ textDecoration: 'none' }}>
                <CreditScoreIcon className="icon" />
                <span>Loan Customer </span>
              </Link>
            </li>
          )}
          {hasAccess('/salescash') && (
            <li className={getActiveClass('/salescash')}>
              <Link to="/salescash" style={{ textDecoration: 'none' }}>
                <MonetizationOnIcon className="icon" />
                <span>Third Party Cash  </span>
              </Link>
            </li>
          )}
          {hasAccess('/paymenterms') && (
            <li className={getActiveClass('/paymenterms')}>
              <Link to="/paymenterms" style={{ textDecoration: 'none' }}>
                <PaymentIcon className="icon" />
                <span>Payment Terms </span>
              </Link>
            </li>
          )}
          {hasAccess('/bikemodels') && (
            <li className={getActiveClass('/bikemodels')}>
              <Link to="/bikemodels" style={{ textDecoration: 'none' }}>
                <TwoWheelerIcon className="icon" />
                <span>Bike Model </span>
              </Link>
            </li>
          )}

          {hasAccess('/stocks') && (
            <li className={getActiveClass('/stocks')}>
              <Link to="/stocks" style={{ textDecoration: 'none' }}>
                <InventoryIcon className="icon" />
                <span>Stocks </span>
              </Link>
            </li>
          )}
          {hasAccess('/roles') && (
            <li className={getActiveClass('/roles')}>
              <Link to="/roles" style={{ textDecoration: 'none' }}>
                <TuneIcon className="icon" />
                <span>Role </span>
              </Link>
            </li>
          )}
          {hasAccess('/reminders') && (
            <li className={getActiveClass('/reminders')}>
              <Link to="/reminders" style={{ textDecoration: 'none' }}>
                <NotificationsActiveIcon className="icon" />
                <span>Reminder </span>
                {reminderCount > 0 && (
                  <span className="badge">{reminderCount}</span>
                )}
              </Link>
            </li>
          )}

          {hasAccess('/activities') && (
            <li className={getActiveClass('/activities')}>
              <Link to="/activities" style={{ textDecoration: 'none' }}>
                <ChangeHistoryIcon className="icon" />
                <span>Activities  Logs</span>
              </Link>
            </li>
          )}
          {hasAccess('/outlets') && (
            <li className={getActiveClass('/activities')}>
              <Link to="/outlets" style={{ textDecoration: 'none' }}>
                <StorefrontIcon className="icon" />
                <span>Outlets </span>
              </Link>
            </li>
          )}
          <li onClick={handleLogout} className={getActiveClass('/login')}>
            <LogoutIcon className="icon" />
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <span>Logout </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
