import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Snackbar, Alert ,MenuItem} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddStockData = () => {
  const navigate = useNavigate();
  const [bikeModel, setBikeModel] = useState({
    supplier: '',
    stockManufacturing: '',
    stockModel: '',
    stockVariants: '',
    stockColour: '',
    engineNumber: '',
    chassisNumber: '',
    condition: '',
    stockInDate: '',
    stockOutDate: '',
    cc: '',
    vendorPrice: '',
    sellingPrice: '',
  });
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const apiURL = process.env.REACT_APP_API_URL;

  const handleInputChange = (field, value) => {
    setBikeModel({ ...bikeModel, [field]: value });
  };

  const handleSaveBikeModel = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        throw new Error('No token found, authorization required');
      }

      const response = await axios.post(`${apiURL}/stocks`, bikeModel, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });

      console.log('Bike model added:', response.data);
      setSnackbarMessage('Bike model added successfully!');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error adding bike model:', error);
      setError('Failed to add bike model. Please try again.');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    navigate('/stocks'); // Redirect after Snackbar closes
  };

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ px: 2 }}
    >
      <Box
        width="100%"
        maxWidth="800px"
        p={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
      >
        <Typography variant="h4" align="center" gutterBottom>
          Add New Bike Model
        </Typography>
        <form>
          <TextField
            label="Supplier"
            value={bikeModel.supplier}
            onChange={(e) => handleInputChange('supplier', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Manufacturing"
            value={bikeModel.stockManufacturing}
            onChange={(e) => handleInputChange('stockManufacturing', e.target.value.toUpperCase())}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Model"
            value={bikeModel.stockModel}
            onChange={(e) => handleInputChange('stockModel', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Variants"
            value={bikeModel.stockVariants}
            onChange={(e) => handleInputChange('stockVariants', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Color"
            value={bikeModel.stockColour}
            onChange={(e) => handleInputChange('stockColour', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Engine Number"
            value={bikeModel.engineNumber}
            onChange={(e) => handleInputChange('engineNumber', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Chassis Number"
            value={bikeModel.chassisNumber}
            onChange={(e) => handleInputChange('chassisNumber', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Condition"
            value={bikeModel.condition}
            onChange={(e) => handleInputChange('condition', e.target.value)}
            fullWidth
            margin="normal"
            select
          >
            <MenuItem value="New"> New</MenuItem>
            <MenuItem value="Used"> Used</MenuItem>
          </TextField>
          <TextField
            label="Stock In Date"
            type="date"
            value={bikeModel.stockInDate}
            onChange={(e) => handleInputChange('stockInDate', e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Stock Out Date"
            type="date"
            value={bikeModel.stockOutDate}
            onChange={(e) => handleInputChange('stockOutDate', e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="CC"
            type="number"
            value={bikeModel.cc}
            onChange={(e) => handleInputChange('cc', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Cost"
            type="number"
            value={bikeModel.vendorPrice}
            onChange={(e) => handleInputChange('vendorPrice', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Selling Price"
            type="number"
            value={bikeModel.sellingPrice}
            onChange={(e) => handleInputChange('sellingPrice', e.target.value)}
            fullWidth
            margin="normal"
          />
        </form>
        <Box display="flex" justifyContent="center" mt={3}>
          <Button onClick={handleSaveBikeModel} variant="contained" style={{ backgroundColor: '#2c9294' }}>
            Add Stock
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ top: 0 }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddStockData;
