import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./HandcashData.scss";

const HandcashData = () => {
  const [invoiceType, setInvoiceType] = useState("CS");
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    tel: "",
    address: "",
    saleExecutive: "",
    currency: "",
    note: "",
    receivedBy: "",
  });
  const [newItem, setNewItem] = useState({
    code: "",
    description: "",
    qty: 0,
    price: 0,
    discount: 0,
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "" });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createdOrder, setCreatedOrder] = useState(null);
  const apiURL= process.env.REACT_APP_API_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem({ ...newItem, [name]: value });
  };

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInvoiceTypeChange = (e) => {
    setInvoiceType(e.target.value);
  };

  const addItem = () => {
    if (!newItem.code || !newItem.description || newItem.qty <= 0 || newItem.price <= 0) {
      setSnackbar({ open: true, message: "Please fill all item fields correctly.", severity: "warning" });
      return;
    }
    const totalPrice = (newItem.qty * newItem.price - newItem.discount).toFixed(2);
    setItems([...items, { ...newItem, totalPrice }]);
    setNewItem({ code: "", description: "", qty: 0, price: 0, discount: 0 });
  };

  const deleteItem = (indexToDelete) => {
    setItems(items.filter((_, index) => index !== indexToDelete));
  };

  const calculateGrandTotal = () => {
    return items.reduce((total, item) => total + parseFloat(item.totalPrice), 0).toFixed(2);
  };

  const isFormValid = () => {
    return (
      formData.name &&
      formData.tel &&
      formData.address &&
      formData.saleExecutive &&
      formData.currency &&
      formData.receivedBy &&
      items.length > 0
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const totalAmount = calculateGrandTotal();
    const payload = { ...formData, invoiceType, items, totalAmount };

    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.post(`${apiURL}/handcashs`, payload,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setSnackbar({ open: true, message: "Order submitted successfully!", severity: "success" });
      setCreatedOrder(response.data.order); // Save created order for display in dialog
      console.log("Response:", response.data);

      // Reset form
      setFormData({
        name: "",
        tel: "",
        address: "",
        saleExecutive: "",
        currency: "",
        note: "",
        receivedBy: "",
      });
      setItems([]);
      setConfirmOpen(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Error submitting order.", severity: "error" });
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadPDF = async () => {
    const input = document.getElementById("order-details");
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 190; // A4 width in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
    pdf.save(`order_${createdOrder.invoiceNo}.pdf`);
  };

  return (
    <Box className="handcash-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>

      <Typography variant="h5" gutterBottom>
        Create Cash Order
      </Typography>
      <Box className="customer-info">
        <Box className="left-section">
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            className="spaced-input"
            value={formData.name}
            onChange={handleFormDataChange}
          />
          <TextField
            label="Tel"
            name="tel"
            variant="outlined"
            size="small"
            fullWidth
            className="spaced-input"
            value={formData.tel}
            onChange={handleFormDataChange}
          />
          <TextField
            label="Address"
            name="address"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={1}
            className="spaced-input"
            value={formData.address}
            onChange={handleFormDataChange}
          />
          <TextField
            label="Currency"
            name="currency"
            variant="outlined"
            size="small"
            fullWidth
            className="spaced-input"
            value={formData.currency}
            onChange={handleFormDataChange}
          />
        </Box>
        <Box className="right-section">
          <FormControl fullWidth size="small" variant="outlined" className="spaced-input">
            <InputLabel>Invoice Type</InputLabel>
            <Select value={invoiceType} onChange={handleInvoiceTypeChange} label="Invoice Type">
              <MenuItem value="CS">Cash Customer (CS)</MenuItem>
              <MenuItem value="Business">Business</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Sale Executive"
            name="saleExecutive"
            variant="outlined"
            size="small"
            fullWidth
            className="spaced-input"
            value={formData.saleExecutive}
            onChange={handleFormDataChange}
          />
          <TextField
            label="Note"
            name="note"
            variant="outlined"
            size="small"
            fullWidth
            className="spaced-input"
            value={formData.note}
            onChange={handleFormDataChange}
          />
          <TextField
            label="Received By"
            name="receivedBy"
            variant="outlined"
            size="small"
            fullWidth
            className="spaced-input"
            value={formData.receivedBy}
            onChange={handleFormDataChange}
          />
        </Box>
      </Box>

      {/* Add Item Section */}
      <Box className="add-item">
        <Typography variant="h6">Add Item</Typography>
        <Box className="add-item-fields">
          <TextField
            label="Item Code"
            name="code"
            value={newItem.code}
            onChange={handleInputChange}
            size="small"
          />
          <TextField
            label="Description"
            name="description"
            value={newItem.description}
            onChange={handleInputChange}
            size="small"
          />
          <TextField
            label="Qty"
            name="qty"
            type="number"
            value={newItem.qty}
            onChange={handleInputChange}
            size="small"
          />
          <TextField
            label="Price/Unit"
            name="price"
            type="number"
            value={newItem.price}
            onChange={handleInputChange}
            size="small"
          />
          <TextField
            label="Discount"
            name="discount"
            type="number"
            value={newItem.discount}
            onChange={handleInputChange}
            size="small"
          />
          <Button variant="contained" color="primary" onClick={addItem}>
            Add Item
          </Button>
        </Box>
      </Box>

      {/* Items Table */}
      <TableContainer component={Paper} className="item-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Item Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Price/Unit</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Total Price</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{`#00${index + 1}`}</TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.qty}</TableCell>
                <TableCell>{item.price}</TableCell>
                <TableCell>{item.discount}</TableCell>
                <TableCell>{item.totalPrice}</TableCell>
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => deleteItem(index)}
                    title="Delete Item"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Total Section */}
      <Box className="total-section">
        <Typography variant="h6">Total: {calculateGrandTotal()}</Typography>
      </Box>

      {/* Submit Button */}
      <Button
        variant="contained"
        color="primary"
        className="submit-button"
        onClick={() => setConfirmOpen(true)}
        disabled={!isFormValid() || isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : "Submit"}
      </Button>

      {/* Confirmation Dialog */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to submit this order?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Created Order Dialog */}
{/* Created Order Dialog */}
{/* Created Order Dialog */}
<Dialog open={!!createdOrder} onClose={() => setCreatedOrder(null)} fullWidth maxWidth="md">
  <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
    Order Details
  </DialogTitle>
  <DialogContent id="order-details">
    {createdOrder && (
      <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
        {/* Invoice Header */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6" sx={{ marginBottom: 1, fontWeight: 'bold', textAlign: 'center' }}>
            Invoice No: {createdOrder.invoiceNo}
          </Typography>
        </Box>

        {/* Customer and Order Details */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 3 }}>
          <Box>
            <Typography><strong>Name:</strong> {createdOrder.name}</Typography>
            <Typography><strong>Tel:</strong> {createdOrder.tel}</Typography>
            <Typography><strong>Address:</strong> {createdOrder.address}</Typography>
            <Typography><strong>Currency:</strong> {createdOrder.currency}</Typography>
          </Box>
          <Box>
            <Typography><strong>Sale Executive:</strong> {createdOrder.saleExecutive}</Typography>
            {/* <Typography><strong>Note:</strong> {createdOrder.note}</Typography> */}
            <Typography><strong>Create At:</strong> {new Date(createdOrder.date).toLocaleString()}</Typography>
            {/* <TableCell></TableCell> */}
            <Typography><strong>Received By:</strong> {createdOrder.receivedBy}</Typography>
          </Box>
        </Box>

        {/* Total Amount */}
     

        {/* Item Table */}
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>No</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Item Code</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Qty</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Price</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Discount</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Total Price (RM)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {createdOrder.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{`#00${index + 1}`}</TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.qty}</TableCell>
                  <TableCell>{item.price.toLocaleString()}</TableCell>
                  <TableCell>{item.discount.toLocaleString()}</TableCell>
                  <TableCell>{item.totalPrice.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
         <Box sx={{ textAlign: 'right', marginTop: 3, marginBottom: 3 }}>
         <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
           Total Amount(RM): {createdOrder.totalAmount.toLocaleString()}
         </Typography>
       </Box>
       <Box sx={{ textAlign: 'left', marginTop: 3, marginBottom: 3 }}>
Note:{createdOrder.note}
       </Box>
       <Box sx={{ textAlign: 'right', marginTop: 3, marginBottom: 3 }}>
Approved: ________________
       </Box>
      </Box>
    )}
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'space-between', padding: '16px' }}>
    <Button
      onClick={downloadPDF}
      color="primary"
      variant="contained"
      sx={{ fontWeight: 'bold' }}
    >
      Download PDF
    </Button>
    <Button
      onClick={() => setCreatedOrder(null)}
      color="secondary"
      variant="outlined"
      sx={{ fontWeight: 'bold' }}
    >
      Close
    </Button>
  </DialogActions>
</Dialog>


    </Box>
  );
};

export default HandcashData;
