import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Box,
  TableSortLabel, // Import TableSortLabel for sorting
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom'; 
import AddScoreDialog from '../AddScoreDialog'; // Ensure the correct path

const useStyles = makeStyles({
  Button: {
    backgroundColor: '#037b7d',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2c9294',
    },
  },
});

const calculateProgress = (attachment) => {
  const statusPoints = {
    approved: 20,
    pending: 0,
    rejected: 0,
  };
  const keys = ['incomeStatement', 'salarySlip', 'epfStatement', 'utilitiesBill', 'ctosReport'];
  let totalProgress = 0;

  keys.forEach((key) => {
    totalProgress += statusPoints[attachment[key].status];
  });

  return totalProgress;
};

const RenderTable = ({
  data,
  columns,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleMenuOpen,
  anchorEl,
  selectedRow,
  handleMenuClose,
  handleEdit,
  handleClickOpenScoreDialog,
  orderBy,
  orderDirection,
  handleSortRequest,
}) => {
  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.field} sortDirection={orderBy === col.field ? orderDirection : false}>
                <TableSortLabel
                  active={orderBy === col.field}
                  direction={orderBy === col.field ? orderDirection : 'asc'}
                  onClick={() => handleSortRequest(col.field)}
                >
                  {col.headerName}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((item) => (
            <TableRow key={item._id}>
              {columns.map((col) => (
                <TableCell key={col.field}>
                  {col.field === 'progress' ? (
                    calculateProgress(item)
                  ) : col.render ? (
                    col.render(item[col.field], item)
                  ) : (
                    item[col.field]?.toString()
                  )}
                </TableCell>
              ))}
              <TableCell>
                <IconButton onClick={(e) => handleMenuOpen(e, item)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedRow === item}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleEdit(item._id)}>Edit</MenuItem>
                  <MenuItem onClick={() => handleClickOpenScoreDialog(item)}>Score</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 40]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

const AttachmentLoan = () => {
  const [attachments, setAttachments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const apiURL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [openScoreDialog, setOpenScoreDialog] = useState(false);
  const [selectedAttachmentLoan, setSelectedAttachmentLoan] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input
  const [orderBy, setOrderBy] = useState('customerName'); // Default sorting field
  const [orderDirection, setOrderDirection] = useState('asc'); // Default sorting direction

  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        setLoading(true);
        const storedToken = localStorage.getItem('token');

        const response = await axios.get(`${apiURL}/attachmentsloans`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const transformData = (data) => {
          return data.map((item) => ({
            ...item,
            customerName: item.customerId ? item.customerId.name : 'Unknown',
          }));
        };

        const transformedData = transformData(response.data);
        setAttachments(transformedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAttachments();
  }, [apiURL]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = (id) => {
    navigate(`/customerloans/attachments/${id}`);
  };

  const handleClickOpenScoreDialog = (item) => {
    setSelectedAttachmentLoan({
      attachmentId: item._id,
      name: item.customerName,
    });
    setOpenScoreDialog(true);
    handleMenuClose();
  };

  const handleCloseScoreDialog = () => {
    setOpenScoreDialog(false);
    setSelectedAttachmentLoan(null);
  };

  const handleSubmitScoreDialog = () => {
    setOpenScoreDialog(false);
  };

  // Sorting logic
  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortAttachments = (array, orderBy, orderDirection) => {
    return array.sort((a, b) => {
      if (orderDirection === 'asc') {
        return a[orderBy] < b[orderBy] ? -1 : a[orderBy] > b[orderBy] ? 1 : 0;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : a[orderBy] < b[orderBy] ? 1 : 0;
      }
    });
  };

  // Filter and sort attachments based on search term and sorting
  const filteredAttachments = attachments.filter((attachment) =>
    attachment.customerName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortedAttachments = sortAttachments(filteredAttachments, orderBy, orderDirection);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const attachmentColumns = [
    { field: 'customerName', headerName: 'Customer' },
    {
      field: 'nric.front',
      headerName: 'NRIC Front',
      render: (value, row) => (
        row.nric?.front ? (
          <a href={row.nric.front} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" style={{ backgroundColor: 'rgb(44, 146, 148)', textTransform: 'capitalize' }}>NRIC Front</Button>
          </a>
        ) : 'N/A'
      ),
    },
    {
      field: 'nric.back',
      headerName: 'NRIC Back',
      render: (value, row) => (
        row.nric?.back ? (
          <a href={row.nric.back} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" style={{ backgroundColor: 'rgb(44, 146, 148)', textTransform: 'capitalize' }}>NRIC Back</Button>
          </a>
        ) : 'N/A'
      ),
    },
    {
      field: 'licence.front',
      headerName: 'Licence Front',
      render: (value, row) => (
        row.licence?.front ? (
          <a href={row.licence.front} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" style={{ backgroundColor: 'rgb(44, 146, 148)', textTransform: 'capitalize' }}>Licence Front</Button>
          </a>
        ) : 'N/A'
      ),
    },
    {
      field: 'licence.back',
      headerName: 'Licence Back',
      render: (value, row) => (
        row.licence?.back ? (
          <a href={row.licence.back} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" style={{ backgroundColor: 'rgb(44, 146, 148)', textTransform: 'capitalize' }}>Licence Back</Button>
          </a>
        ) : 'N/A'
      ),
    },
    {
      field: 'incomeStatement.path',
      headerName: 'Income Statement',
      render: (value, row) => (
        <div>
          {row.incomeStatement?.path ? (
            <a href={row.incomeStatement.path} target="_blank" rel="noopener noreferrer">
              <Button variant="contained" style={{ backgroundColor: 'rgb(44, 146, 148)', textTransform: 'capitalize' }}>Income Statement</Button>
            </a>
          ) : 'N/A'}
          <div>Status: {row.incomeStatement?.status}</div>
        </div>
      ),
    },
    {
      field: 'salarySlip.path',
      headerName: 'Salary Slip',
      render: (value, row) => (
        <div>
          {row.salarySlip?.path ? (
            <a href={row.salarySlip.path} target="_blank" rel="noopener noreferrer">
              <Button variant="contained" style={{ backgroundColor: 'rgb(44, 146, 148)', textTransform: 'capitalize' }}>Salary Slip</Button>
            </a>
          ) : 'N/A'}
          <div>Status: {row.salarySlip?.status}</div>
        </div>
      ),
    },
    {
      field: 'epfStatement.path',
      headerName: 'EPF Statement',
      render: (value, row) => (
        <div>
          {row.epfStatement?.path ? (
            <a href={row.epfStatement.path} target="_blank" rel="noopener noreferrer">
              <Button variant="contained" style={{ backgroundColor: 'rgb(44, 146, 148)', textTransform: 'capitalize' }}>EPF Statement</Button>
            </a>
          ) : 'N/A'}
          <div>Status: {row.epfStatement?.status}</div>
        </div>
      ),
    },
    {
      field: 'utilitiesBill.path',
      headerName: 'Utilities Bill',
      render: (value, row) => (
        <div>
          {row.utilitiesBill?.path ? (
            <a href={row.utilitiesBill.path} target="_blank" rel="noopener noreferrer">
              <Button variant="contained" style={{ backgroundColor: 'rgb(44, 146, 148)', textTransform: 'capitalize' }}>Utilities Bill</Button>
            </a>
          ) : 'N/A'}
          <div>Status: {row.utilitiesBill?.status}</div>
        </div>
      ),
    },
    {
      field: 'ctosReport.path',
      headerName: 'CTOS Report',
      render: (value, row) => (
        <div>
          {row.ctosReport?.path ? (
            <a href={row.ctosReport.path} target="_blank" rel="noopener noreferrer">
              <Button variant="contained" style={{ backgroundColor: 'rgb(44, 146, 148)', textTransform: 'capitalize' }}>CTOS Report</Button>
            </a>
          ) : 'N/A'}
          <div>Status: {row.ctosReport?.status}</div>
        </div>
      ),
    },
    {
      field: 'scores',
      headerName: 'Scores',
      render: (value, row) => `${row.totalScore}/${row.totalOutOfScore}`,
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" padding="5px">
        <TextField
          label="Search by Customer Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
          style={{ width: '300px' }}
        />
      </Box>
      <RenderTable
        data={sortedAttachments} // Pass sorted data to the table
        columns={attachmentColumns}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleMenuOpen={handleMenuOpen}
        anchorEl={anchorEl}
        selectedRow={selectedRow}
        handleMenuClose={handleMenuClose}
        handleEdit={handleEdit}
        handleClickOpenScoreDialog={handleClickOpenScoreDialog}
        orderBy={orderBy}
        orderDirection={orderDirection}
        handleSortRequest={handleSortRequest}
      />
      {openScoreDialog && selectedAttachmentLoan && (
        <AddScoreDialog
          open={openScoreDialog}
          onClose={handleCloseScoreDialog}
          onSubmit={handleSubmitScoreDialog}
          newReference={selectedAttachmentLoan} // Pass the selected item to the dialog
        />
      )}
    </>
  );
};

export default AttachmentLoan;
