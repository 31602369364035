import React, { useEffect, useState } from 'react';
import {
  Table, TableContainer, Paper, TablePagination, IconButton, Menu, MenuItem, TableHead, TableBody, TableCell, TableRow, TableSortLabel
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const useStyles = makeStyles({
  button: {
    backgroundColor: '#037b7d',
    color: '#fff',
    zIndex: 10,
    '&:hover': {
      backgroundColor: '#2c9294',
    },
  },
});

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  return isNaN(date) ? 'Invalid date' : date.toLocaleDateString('en-US', options);
};

const SalesCustomerLoan = () => {
  const [customerLoans, setCustomerLoans] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('name'); // Default sorting by name
  const [orderDirection, setOrderDirection] = useState('asc'); // Default sorting direction

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate
  const apiURL = process.env.REACT_APP_API_URL;
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpenEditDialog = () => {
    if (selectedLoan) {
      navigate(`/salesloans/${selectedLoan._id}`); // Navigate to EditLoan route with loan ID
    }
    handleMenuClose();
  };

  const handleMenuClick = (event, loan) => {
    setAnchorEl(event.currentTarget);
    setSelectedLoan(loan);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((value, key) => value?.[key], obj);
  };

  const sortData = (array, orderBy, orderDirection) => {
    return array.sort((a, b) => {
      const aValue = getNestedValue(a, orderBy) ? getNestedValue(a, orderBy).toString().toLowerCase() : '';
      const bValue = getNestedValue(b, orderBy) ? getNestedValue(b, orderBy).toString().toLowerCase() : '';

      if (orderDirection === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
      }
    });
  };

  useEffect(() => {
    const fetchCustomerLoans = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/customerloans/my-loans`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setCustomerLoans(response.data);
      } catch (error) {
        console.error('Error fetching customer loans:', error);
      }
    };

    fetchCustomerLoans();
  }, [apiURL]);

  const sortedLoans = sortData(customerLoans, orderBy, orderDirection);

  const customerLoanColumns = [
    { field: 'name', headerName: 'Name' },
    { field: 'nricNo', headerName: 'NRIC No' },
    { field: 'mobileNumber', headerName: 'Mobile' },
    { field: 'email', headerName: 'Email' },
    { field: 'gender', headerName: 'Gender' },
    { field: 'maritalStatus', headerName: 'Marital Status' },
    { field: 'race', headerName: 'Race' },
    { field: 'address', headerName: 'Address' },
    { field: 'status', headerName: 'Status' },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      render: (loan) => formatDate(loan.updatedAt),
    },
  ];

  return (
    <div>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {customerLoanColumns.map((col) => (
                <TableCell key={col.field} sortDirection={orderBy === col.field ? orderDirection : false}>
                  <TableSortLabel
                    active={orderBy === col.field}
                    direction={orderBy === col.field ? orderDirection : 'asc'}
                    onClick={() => handleSortRequest(col.field)}
                  >
                    {col.headerName}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedLoans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((loan) => (
              <TableRow key={loan._id}>
                {customerLoanColumns.map((col) => (
                  <TableCell key={col.field} className={`${classes.normalText}`}>
                    {col.render ? col.render(loan) : getNestedValue(loan, col.field)}
                  </TableCell>
                ))}
                <TableCell>
                  <IconButton onClick={(e) => handleMenuClick(e, loan)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedLoan?._id === loan._id}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleClickOpenEditDialog}>Edit Loan</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 40]}
          component="div"
          count={customerLoans.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default SalesCustomerLoan;
