import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';

import UserDetails from '../../components/datatable/UserDetails';
import './UserDetailPage.scss';
const UserDetailPage = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
        {/* <List />
         */}
         {/* <List  /> */}
        <UserDetails />
      </div>
    </div>
  );
};

export default UserDetailPage;
