import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MonthlyPaymentsTable = ({ data }) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  return (
    <div className="table-container">
      <table className="monthlyPaymentsTable">
        <thead>
          <tr>
            <th>Month</th>
            <th>Current Cash Deficit /Payment Forecost</th>
            <th>Accumulated </th>
          </tr>
        </thead>
        <tbody>
          {months.map((month) => (
            <tr key={month}>
              <td>{month}</td>
              <td>{data[month]?.incremental?.toFixed(2) || '0.00'}</td>
              <td>{data[month]?.total?.toFixed(2) || '0.00'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const FinanceLoanSummary = () => {
  const [yearlySummary, setYearlySummary] = useState({});
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${apiURL}/financeloans/state`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.data && response.data.yearlySummary) {
          setYearlySummary(response.data.yearlySummary);
        } else {
          console.error('Unexpected API response structure:', response.data);
          setError('Unexpected API response structure.');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch finance loan summary data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [apiURL]);

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  if (isLoading) {
    return <div className="loading">Loading finance loan summary...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="financeLoanSummary">
      <h2> Forecost</h2>
      <div className="yearSelector">
        <label htmlFor="year">Select Year: </label>
        <select id="year" value={selectedYear} onChange={handleYearChange}>
          {Object.keys(yearlySummary).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      {yearlySummary[selectedYear] ? (
        <MonthlyPaymentsTable data={yearlySummary[selectedYear]} />
      ) : (
        <div className="no-data">No data available for the selected year.</div>
      )}
    </div>
  );
};

export default FinanceLoanSummary;
