import React, { useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const SalaryDataUpdate = () => {
  const { salaryId } = useParams();
  const printRef = useRef();

  const [salaryData, setSalaryData] = React.useState({
    basicInfo: {
      name: "",
      NIC: "",
      salaryMonth: "",
    },
    incomeDetails: [],
    epfEmployee: 0,
    epfEmployer: 0,
    socsoEmployee: 0,
    socsoEmployer: 0,
    eisEmployee: 0,
    eisEmployer: 0,
    pcbTotal: 0,
    advanceDeduction: 0,
    staffLoanDeduction: 0,
    netSalary: 0,
    createdAt: "",
  });

  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (salaryId) {
      fetchSalaryData();
    }
  }, [salaryId]);

  const fetchSalaryData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiURL}/salaries/${salaryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSalaryData(response.data || {});
    } catch (error) {
      console.error("Error fetching salary data:", error.message);
      alert("Failed to load salary data.");
    }
  };

  const handlePrint = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const dataURL = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const imgProps = pdf.getImageProperties(dataURL);
    const pdfWidth = pageWidth - 20;
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(dataURL, "PNG", 10, 10, pdfWidth, pdfHeight);
    pdf.save("salary-voucher.pdf");
  };

  const findIncomeDetail = (label) =>
    salaryData.incomeDetails.find((item) => item.label === label)?.amount || 0;

  return (
    <Box padding={3}>
     
      <Box ref={printRef} padding={3} border="1px solid #ccc">
        {/* Header Section */}
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Box>
            <Typography variant="body1">
              <strong>Pay To:</strong> {salaryData.basicInfo.name || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>NIC:</strong> {salaryData.basicInfo.NIC || "N/A"}
            </Typography>
          </Box>
          <Box>
          <Typography variant="h4" align="center" gutterBottom>
        Salary Voucher
      </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              <strong>Pay Day:</strong>{" "}
              {new Date(salaryData.createdAt).toLocaleDateString() || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Salary Month:</strong> {salaryData.basicInfo.salaryMonth || "N/A"}
            </Typography>
          </Box>
        </Box>

        {/* Two Tables Side by Side */}
        <Box display="flex" justifyContent="space-between" gap={3}>
          {/* Left Table */}
          <TableContainer component={Paper} style={{ flex: 1 }}>
            <Table>
              <TableBody>
                {/* Earnings Section */}
                <TableRow>
                  <TableCell>BASIC PAY</TableCell>
                  <TableCell>{findIncomeDetail("Salary")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>OVERTIME</TableCell>
                  <TableCell>{findIncomeDetail("Overtime")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>BONUS</TableCell>
                  <TableCell>{findIncomeDetail("Bonus")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>COMMISSION</TableCell>
                  <TableCell>{findIncomeDetail("Commission")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ALLOWANCES <b>RM {findIncomeDetail("Transport Allowance")}</b> OTHER ALLOWANCE <b> RM{findIncomeDetail("Other Allowance")}</b> </TableCell>
                  <TableCell>
                    {findIncomeDetail("Transport Allowance") +
                      findIncomeDetail("Other Allowance")
                      }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>(A) GROSS PAY</TableCell>
                  <TableCell>
                    {salaryData.incomeDetails.reduce(
                      (sum, item) => sum + item.amount,
                      0
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>(B) TOTAL DEDUCTIONS:<br></br> EPF:<b>  RM{salaryData.epfEmployee}</b>, SOCSO:<b>  RM{salaryData.socsoEmployee}</b>,  EIS:<b> RM{salaryData.eisEmployee}</b>,
                  EIS: <b>RM{salaryData.pcbTotal}</b>,  Adv. : <b>RM{salaryData.advanceDeduction}</b>, Loan. : <b>RM{salaryData.staffLoanDeduction}</b>,</TableCell>
                  <TableCell>
                    {(
                      parseFloat(salaryData.epfEmployee || 0) +
                      parseFloat(salaryData.socsoEmployee || 0) +
                      parseFloat(salaryData.eisEmployee || 0) +
                      parseFloat(salaryData.pcbTotal || 0) +
                      parseFloat(salaryData.advanceDeduction || 0) +
                      parseFloat(salaryData.staffLoanDeduction || 0)
                    ).toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>(C) NET PAY</TableCell>
                  <TableCell>{salaryData.netSalary.toFixed(2)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Right Table */}
          <TableContainer component={Paper} style={{ flex: 1 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell> Overtime Hour / Rate</TableCell>
                  <TableCell> </TableCell>
                </TableRow>
               
                <TableRow>
                  <TableCell>EMPLOYER'S EPF</TableCell>
                  <TableCell>{salaryData.epfEmployer || "0.00"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>EMPLOYER'S SOCSO</TableCell>
                  <TableCell>{salaryData.socsoEmployer || "0.00"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>EMPLOYER'S EIS</TableCell>
                  <TableCell>{salaryData.eisEmployer || "0.00"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Contribution</TableCell>
                  <TableCell>
                    {(
                      parseFloat(salaryData.epfEmployer || 0) +
                      parseFloat(salaryData.socsoEmployer || 0) +
                      parseFloat(salaryData.eisEmployer || 0)
                    ).toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Prepared By</TableCell>
                  <TableCell>_ _ _ _ _ _ _ _ _ _ _ _</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Approved By</TableCell>
                  <TableCell>_ _ _ _ _ _ _ _ _ _ _ _</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Employee's Signature</TableCell>
                  <TableCell>_ _ _ _ _ _ _ _ _ _ _ _</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {/* Print Button */}
      <Box mt={3} textAlign="center">
        <Button variant="contained" color="primary" onClick={handlePrint}>
          Print Salary Voucher
        </Button>
      </Box>
    </Box>
  );
};

export default SalaryDataUpdate;
