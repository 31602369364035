import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Divider,
  Box,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';

const OutletData = () => {
  const [outlets, setOutlets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false); // Dialog for editing/adding outlet
  const [outletName, setOutletName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');
  const [logo, setLogo] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editOutletId, setEditOutletId] = useState(null);
  const apiURL = process.env.REACT_APP_API_URL;

  // Fetch outlets function
  const fetchOutlets = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.get(`${apiURL}/outlets`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setOutlets(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch outlets when component mounts
  useEffect(() => {
    fetchOutlets();
  }, [apiURL]);

  const handleEdit = (outlet) => {
    setIsEditing(true);
    setEditOutletId(outlet._id);
    setOutletName(outlet.name);
    setEmail(outlet.email);
    setPhone(outlet.phone);
    setLocation(outlet.location);
    setLogo(outlet.logo);
    setOpenDialog(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const storedToken = localStorage.getItem('token'); // Retrieve token from localStorage
    const outletData = {
      name: outletName,
      email: email,
      phone: phone,
      location: location,
      logo: logo,
    };
  
    try {
      const headers = {
        Authorization: `Bearer ${storedToken}`,
      };
  
      if (isEditing) {
        // PUT request to update the outlet
        await axios.put(`${apiURL}/api/outlets/${editOutletId}`, outletData, { headers });
      } else {
        // POST request to create a new outlet
        await axios.post(`${apiURL}/api/outlets`, outletData, { headers });
      }
  
      setOpenDialog(false);
      fetchOutlets(); // Refresh outlets after adding/editing
    } catch (err) {
      console.error('Error creating/updating outlet:', err);
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" style={{ marginLeft: '10px' }}>Outlets</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsEditing(false);
            setOpenDialog(true);
          }}
          style={{ backgroundColor: '#2c9294', marginRight: '10px' }}
        >
          Add Outlet
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Outlet Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Logo</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {outlets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((outlet, index) => (
            <TableRow key={outlet._id}>
              <TableCell>{page * rowsPerPage + index + 1}</TableCell>
              <TableCell>{outlet.name}</TableCell>
              <TableCell>{outlet.email}</TableCell>
              <TableCell>{outlet.phone}</TableCell>
              <TableCell>{outlet.location}</TableCell>
              <TableCell>
                {outlet.logo && <img src={outlet.logo} alt="Logo" style={{ width: 50, height: 50, objectFit: 'cover' }} />}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(outlet)}>
                  <Edit color="primary" />
                </IconButton>
                {/* <IconButton onClick={() => handleDelete(outlet._id)}>
                  <Delete color="error" />
                </IconButton> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={outlets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
      />

      {/* Dialog for adding/editing outlets */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"  // Set dialog max width to small to reduce width
        fullWidth
      >
        <DialogTitle>{isEditing ? 'Edit Outlet' : 'Create Outlet'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Outlet Name"
              variant="outlined"
              fullWidth
              value={outletName}
              onChange={(e) => setOutletName(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Location"
              variant="outlined"
              fullWidth
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Logo URL"
              variant="outlined"
              fullWidth
              value={logo}
              onChange={(e) => setLogo(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />

            <Button variant="contained" color="primary" type="submit" fullWidth sx={{ marginTop: 3 }}>
              {isEditing ? 'Update Outlet' : 'Create Outlet'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OutletData;
