import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import OutletData from '../../components/outletdata/OutletData';

const Outlet = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
  
         <OutletData />
      </div>
    </div>
  );
};

export default Outlet;

