import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PaymentSummary = () => {
  const [paymentsByYear, setPaymentsByYear] = useState({});
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const apiURL = process.env.REACT_APP_API_URL; // Replace with your fixed API URL if necessary

  // Fetch payment data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiURL}/paymenterms/state`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`, // assuming token is stored in localStorage
          },
        });
        setPaymentsByYear(response.data.paymentsByYear || {});
      } catch (error) {
        console.error('Error fetching payment data:', error);
      }
    };

    fetchData();
  }, [apiURL]);

  // Handle year selection change
  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  // Table component to display the data
  const MonthlyPaymentsTable = ({ data }) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];

    return (
      <table className="monthlyPaymentsTable" border="1">
        <thead>
          <tr>
            <th>Month</th>
            <th>Actual In. Collected</th>
            <th>Accumulated In. Cltd.</th>
          </tr>
        </thead>
        <tbody>
          {months.map((month) => (
            <tr key={month}>
              <td>{month}</td>
              <td>{data[month]?.monthly?.toFixed(2) || '0.00'}</td>
              <td>{data[month]?.cumulative?.toFixed(2) || '0.00'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="payment-summary">
      <h2>Payment Summary</h2>
      <div className="yearSelector">
        <label htmlFor="year">Select Year: </label>
        <select id="year" value={selectedYear} onChange={handleYearChange}>
          {Object.keys(paymentsByYear).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <MonthlyPaymentsTable data={paymentsByYear[selectedYear] || {}} />
    </div>
  );
};

export default PaymentSummary;
