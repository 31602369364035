import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Plannertable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedToken = localStorage.getItem('token');

        const response = await fetch(`${process.env.REACT_APP_API_URL}/planners`, {
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${storedToken}`, 
          },
        });
        
      
        
        const result = await response.json();
        console.log("API Response:", result.data); 
        setData(result.data || []); 
        setLoading(false);
      } catch (error) {
        console.error("Error fetching planners:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  // Divide data into distinct parts based on content
  const topTableData = data.filter((item) => item.totalCreditSales); // Data for the first table
  const bottomTableData = data.filter((item) => item.bInstallmentsCollected); // Data for the second table

  return (
    <div style={{ padding: "20px" }}>
    
      {/* Add Planner Button Aligned to the Right */}
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/planner/add")}
        >
          Add Planner
        </Button>
      </div>

      {/* Top Table */}
      <Typography variant="h6" gutterBottom>
        CF Planner
      </Typography>
      <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Month</strong></TableCell>
              <TableCell><strong>Year</strong></TableCell>
              <TableCell><strong>Total Credit Sales</strong></TableCell>
              <TableCell><strong>Down Payments Received</strong></TableCell>
              <TableCell><strong>Cost</strong></TableCell>
              <TableCell><strong>Loan to Customer</strong></TableCell>
              <TableCell><strong>Accumulated LC</strong></TableCell>
              <TableCell><strong>Loan Disbursements</strong></TableCell>
              <TableCell><strong>Accumulated LD</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topTableData.map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.month}</TableCell>
                <TableCell>{item.year}</TableCell>
                <TableCell>{`RM${item.totalCreditSales?.toFixed(2) || "0.00"}`}</TableCell>
                <TableCell>{`RM${item.downPaymentsReceived?.toFixed(2) || "0.00"}`}</TableCell>
                <TableCell>{`RM${item.cost?.toFixed(2) || "0.00"}`}</TableCell>
                <TableCell>{`RM${item.amountLoanToCustomer?.toFixed(2) || "0.00"}`}</TableCell>
                <TableCell>{`RM${item.amountLoanToCustomerAccumulated?.toFixed(2) || "0.00"}`}</TableCell>
                <TableCell>{`RM${item.loanDisbursements?.toFixed(2) || "0.00"}`}</TableCell>
                <TableCell>{`RM${item.loanDisbursementsAccumulated?.toFixed(2) || "0.00"}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider style={{ margin: "20px 0" }} />

      {/* Bottom Table */}
      <Typography variant="h6" gutterBottom>
        Forecast & BUDGET Instalmet Collection
      </Typography>
      {bottomTableData.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Month</strong></TableCell>
                <TableCell><strong>Year</strong></TableCell>
                <TableCell><strong>B. Installments Collected</strong></TableCell>
                <TableCell><strong>B Installments Accumulated</strong></TableCell>
                <TableCell><strong>Forecast Cash Deficit</strong></TableCell>
                <TableCell><strong>Forecast Cash Deficit Accumulated</strong></TableCell>
                {/* <TableCell><strong>Loan to Customer</strong></TableCell> */}
                {/* <TableCell><strong>Accumulated LC</strong></TableCell> */}
                <TableCell><strong>Actual In. Collected</strong></TableCell>
                <TableCell><strong>Actual In. Collected Accumulated</strong></TableCell>
                <TableCell><strong>Budget Cash Deficit</strong></TableCell>
                <TableCell><strong>Budget Cash Deficit Accumulated </strong></TableCell>
                {/* <TableCell><strong>Accumulated LD</strong></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {bottomTableData.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>{item.month || "N/A"}</TableCell>
                  <TableCell>{item.year || "N/A"}</TableCell>
                  <TableCell>{`RM${item.bInstallmentsCollected?.toFixed(2) || "N/A"}`}</TableCell>
                  <TableCell>{`RM${item.bInstallmentsAccumulated?.toFixed(2) || "N/A"}`}</TableCell>
                  <TableCell>{`RM${item.currentCashDeficit?.toFixed(2) || "N/A"}`}</TableCell>
                  <TableCell>{`RM${item.currentCashDeficitAccumulated?.toFixed(2) || "N/A"}`}</TableCell>
                  <TableCell>{`RM${item.actualInCollected?.toFixed(2) || "N/A"}`}</TableCell>
                  <TableCell>{`RM${item.actualInCollectedAccumulated?.toFixed(2) || "N/A"}`}</TableCell>
                  <TableCell>{`RM${item.currentBudgetCashDeficit?.toFixed(2) || "N/A"}`}</TableCell>
                  <TableCell>{`RM${item.currentBudgetCashDeficitAccumulated?.toFixed(2) || "N/A"}`}</TableCell>
                  {/* <TableCell>{`RM${item.accCashDeficit?.toFixed(2) || "N/A"}`}</TableCell> */}
                  {/* <TableCell>{item.actualInPercentage ? `${item.actualInPercentage}%` : "N/A"}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" color="textSecondary">
          No forecast data available.
        </Typography>
      )}
    </div>
  );
};

export default Plannertable;
