import React, { useState } from "react";
import {
  Tab,
  Tabs,
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function SearchData() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [collections, setCollections] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  const availableCollections = [
    "customerloans",
    "employmentloans",
    "referencesLoans",
    "financeloans",
    "scores",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!startDate || !endDate || collections.length === 0) {
      alert("Please select all required fields.");
      return;
    }

    // Fetch data from API
    const query = `${apiURL}/search?startDate=${startDate}&endDate=${endDate}&collections=${collections.join(",")}`;

    try {
      const response = await fetch(query);
      const result = await response.json();
      if (result.success) {
        setData(result.results); // Use the API response directly
        setTabIndex(0); // Reset to the first tab
      } else {
        alert("Error fetching data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const renderScores = (scores) => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>View Scores</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Score</TableCell>
                <TableCell>Out Of</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scores.map((score, index) => (
                <TableRow key={index}>
                  <TableCell>{score.title}</TableCell>
                  <TableCell>{score.score}</TableCell>
                  <TableCell>{score.scoreOutOf}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderTable = (data, collection) => {
    if (!data || data.length === 0) {
      return <p>No data available</p>;
    }

    // Define fields to display for each collection
    const fieldsToDisplay = {
      customerloans: [
        "name",
        "status",
        "nricNo",
        "mobileNumber",
        "email",
        "gender",
        "maritalStatus",
        "race",
        "address",
        "updatedAt",
      ],
      employmentloans: [
        "customerId",
        "occupation",
        "occupationType",
        "employmentLength",
        "employerName",
        "employerPhoneNumber",
        "monthlyGrossSalary",
        "monthlyNetSalary",
        "salaryCreditingBank",
        "salaryCreditingDay",
      ],
      referencesLoans: [
        "customername", 
        "name",
        "nricNo",
        "contactNumber",
        "relationship",
        "outletId",
        "createdBy",
        "createdAt",
        "updatedAt",
      ],
      financeloans: [
        "customerName",
        "NIC",
        "status",
        "stockManufacturing",
        "stockModel",
        "financingAmount",
        "tenure",
        "paidInstallment",
        "unpaidInstallment",
        "overdueInstallment",
        "monthlyAmount",
      ],
      scores: ["customerName", "auditTrail", "scores"], // Customize scores if needed
    };

    // Get the fields for the current collection
    const fields = fieldsToDisplay[collection] || Object.keys(data[0]);

    return (
      <Table>
        <TableHead>
          <TableRow>
            {fields.map((field) => (
              <TableCell key={field}>{field.toUpperCase()}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {fields.map((field, colIndex) => (
                <TableCell key={colIndex}>
                  {field === "scores" && Array.isArray(row[field])
                    ? renderScores(row[field]) // Render scores as an accordion
                    : field === "auditTrail"
                    ? row[field]?.map((audit, index) => (
                        <div key={index}>
                          <strong>User:</strong> {audit.userName} <br />
                          <strong>Action:</strong> {audit.action} <br />
                          <strong>Timestamp:</strong> {audit.timestamp}
                        </div>
                      ))
                    : typeof row[field] === "object" && row[field] !== null
                    ? JSON.stringify(row[field])
                    : row[field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Box sx={{ width: "95%", padding: 1 }}>
      {/* Filter Form */}
      <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", marginBottom: 2 }}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
          />
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="collections-label">Collections</InputLabel>
            <Select
              labelId="collections-label"
              multiple
              value={collections}
              onChange={(e) => setCollections(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
            >
              {availableCollections.map((collection) => (
                <MenuItem key={collection} value={collection}>
                  {collection}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" color="primary">
            Filter
          </Button>
        </Box>
      </form>

      {/* Tabs and Data Display */}
      {data.length > 0 && (
        <>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {data.map((collection, index) => (
              <Tab label={collection.collection.toUpperCase()} key={index} />
            ))}
          </Tabs>
          <Box sx={{ padding: 1 }}>
            {renderTable(data[tabIndex]?.data, data[tabIndex]?.collection)}
          </Box>
        </>
      )}
    </Box>
  );
}

export default SearchData;
