import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import SalaryTable from '../../components/salarydata/SalaryTable';


const Salary = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
        {/* <List />
         */}
         {/* <List  /> */}
         <SalaryTable/>
      </div>
    </div>
  );
};

export default Salary;

