import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import MonthlyPaymentsTable from './MonthlyPaymentsTable';
import FinancingSummary from './FinancingSummary';
import PaymentSummary from './PaymentSummary';
import StockSummary from './StockSummary';


import './Forecast.scss';
import FinanceLoanSummary from './MonthlyPaymentsTable';
import ForecastSummary from './ForecastSummary';
import LoanToCustomer from './LoanToCustomer';
import PaymentermsBalance from './Paymentermsbalance';

const Forecast = () => {
  const [paymentsByYear, setPaymentsByYear] = useState({});
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const apiURL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiURL}/financeloans/state`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.data && response.data.monthlySummary) {
          setPaymentsByYear({ [selectedYear]: response.data.monthlySummary });
        } else {
          console.error('Unexpected API response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [apiURL, selectedYear]);


  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  const dataForSelectedYear = paymentsByYear[selectedYear] || {
    January: { incremental: 0, total: 0 },
    February: { incremental: 0, total: 0 },
    March: { incremental: 0, total: 0 },
    April: { incremental: 0, total: 0 },
    May: { incremental: 0, total: 0 },
    June: { incremental: 0, total: 0 },
    July: { incremental: 0, total: 0 },
    August: { incremental: 0, total: 0 },
    September: { incremental: 0, total: 0 },
    October: { incremental: 0, total: 0 },
    November: { incremental: 0, total: 0 },
    December: { incremental: 0, total: 0 },
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
      
           <div className="sideBySideContainer">
            <div className="tableBox">
              <MonthlyPaymentsTable data={dataForSelectedYear} />
            </div>

            <div className="tableBox">
              <FinancingSummary />
            </div>
            <div className="tableBox">
              <StockSummary />
            </div>

          </div>
          <div className="sideBySideContainer">
            <div className="tableBox">
              <PaymentSummary />
            </div>
            <div className="tableBox">
              <LoanToCustomer />
            </div>
            <div className="tableBox">
              <PaymentermsBalance />
            </div>


         
        </div>
      </div>
    </div>
  );
};

export default Forecast;
