import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  TableSortLabel // Add TableSortLabel for sorting
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { formatDate } from '../../utils/formatDate'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';

const StockData = () => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc'); // State for sorting order
  const [orderBy, setOrderBy] = useState('supplier'); // State for sorting column
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search term
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/stocks`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        setStocks(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStocks();
  }, [apiURL]);

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedModelId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedModelId(null);
  };

  const handleEdit = () => {
    if (selectedModelId) {
      navigate(`/stocks/${selectedModelId}`);
    }
    handleMenuClose();
  };

  const handleDeleteConfirmation = () => {
    setOpenDialog(true);
  };

  const handleDeleteCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    if (selectedModelId) {
      try {
        const storedToken = localStorage.getItem('token');
        await axios.delete(`${apiURL}/stocks/${selectedModelId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setStocks((prevStocks) =>
          prevStocks.filter((stock) => stock._id !== selectedModelId)
        );
      } catch (err) {
        console.error('Error deleting stock:', err);
      } finally {
        handleMenuClose();
        handleDeleteCloseDialog();
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Sort stocks based on the selected column and order
  const sortedStocks = stocks.sort((a, b) => {
    if (orderBy === 'vendorPrice' || orderBy === 'sellingPrice' || orderBy === 'cc') {
      return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
    } else if (orderBy === 'stockInDate' || orderBy === 'stockOutDate' || orderBy === 'createdAt' || orderBy === 'updatedAt') {
      return order === 'asc'
        ? new Date(a[orderBy]) - new Date(b[orderBy])
        : new Date(b[orderBy]) - new Date(a[orderBy]);
    } else {
      return order === 'asc'
        ? (a[orderBy] > b[orderBy] ? 1 : -1)
        : (a[orderBy] < b[orderBy] ? 1 : -1);
    }
  });

  // Filter stocks based on search term for selected fields
  const filteredStocks = sortedStocks.filter((stock) => {
    const supplier = stock.supplier?.toLowerCase() || '';
    const manufacture = stock.stockManufacturing?.toLowerCase() || '';
    const model = stock.stockModel?.toLowerCase() || '';
    const variants = stock.stockVariants?.toLowerCase() || '';
    const color = stock.stockColour?.toLowerCase() || '';
    const engineNo = stock.engineNumber?.toLowerCase() || '';
    const cc = stock.cc ? stock.cc.toString() : '';

    return (
      supplier.includes(searchTerm.toLowerCase()) ||
      manufacture.includes(searchTerm.toLowerCase()) ||
      model.includes(searchTerm.toLowerCase()) ||
      variants.includes(searchTerm.toLowerCase()) ||
      color.includes(searchTerm.toLowerCase()) ||
      engineNo.includes(searchTerm.toLowerCase()) ||
      cc.includes(searchTerm)
    );
  });

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }


  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="0px">
        <TextField
          label="Search by Supplier, Manufacture, Model, Variants, Color, Engine No, or CC"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
          style={{ width: '400px', marginLeft: '10px', marginTop: '10px' }} // Adjust width and styling as necessary
        />
        <Button
          variant="contained"
          color="primary"
          href="/stocks/AddStock"
          style={{ backgroundColor: '#2c9294', marginRight: '10px' }}
        >
          Add Stock
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'supplier'}
                direction={orderBy === 'supplier' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'supplier')}
              >
                Supplier
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'stockManufacturing'}
                direction={orderBy === 'stockManufacturing' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'stockManufacturing')}
              >
                Manufacture
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'stockModel'}
                direction={orderBy === 'stockModel' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'stockModel')}
              >
                Model
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'stockVariants'}
                direction={orderBy === 'stockVariants' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'stockVariants')}
              >
                Variants
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'stockColour'}
                direction={orderBy === 'stockColour' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'stockColour')}
              >
                Color
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'engineNumber'}
                direction={orderBy === 'engineNumber' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'engineNumber')}
              >
                Engine No.
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'chassisNumber'}
                direction={orderBy === 'chassisNumber' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'chassisNumber')}
              >
                Chassis No.
              </TableSortLabel>


            </TableCell>
            <TableCell>
              
            <TableSortLabel
                active={orderBy === 'condition'}
                direction={orderBy === 'condition' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'condition')}
              >
               Condition
              </TableSortLabel>
             
              </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'stockInDate'}
                direction={orderBy === 'stockInDate' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'stockInDate')}
              >
                In Date
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'stockOutDate'}
                direction={orderBy === 'stockOutDate' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'stockOutDate')}
              >
                Out Date
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'cc'}
                direction={orderBy === 'cc' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'cc')}
              >
                CC
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'vendorPrice'}
                direction={orderBy === 'vendorPrice' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'vendorPrice')}
              >
                Cost
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'sellingPrice'}
                direction={orderBy === 'sellingPrice' ? order : 'asc'}
                onClick={(event) => handleRequestSort(event, 'sellingPrice')}
              >
                Sales Price
              </TableSortLabel>
            </TableCell>
            {/* <TableCell>Created At</TableCell> */}
            <TableCell>Updated At</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredStocks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((model, index) => (
            <TableRow key={model._id}>
              <TableCell>{page * rowsPerPage + index + 1}</TableCell> {/* Running number */}
              <TableCell>{model.supplier}</TableCell>
              <TableCell>{model.stockManufacturing}</TableCell>
              <TableCell>{model.stockModel}</TableCell>
              <TableCell>{model.stockVariants}</TableCell>
              <TableCell>{model.stockColour}</TableCell>
              <TableCell>{model.engineNumber}</TableCell>
              <TableCell>{model.chassisNumber}</TableCell>
              <TableCell>{model.condition}</TableCell>
              <TableCell>{formatDate(model.stockInDate)}</TableCell>
              <TableCell>{formatDate(model.stockOutDate)}</TableCell>
              <TableCell>{model.cc}</TableCell>
              <TableCell>{model.vendorPrice}</TableCell>
              <TableCell>{model.sellingPrice}</TableCell>
              {/* <TableCell>{formatDate(model.createdAt)}</TableCell> */}
              <TableCell>{formatDate(model.updatedAt)}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => handleMenuOpen(event, model._id)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl) && selectedModelId === model._id}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={filteredStocks.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={openDialog}
        onClose={handleDeleteCloseDialog}
      >
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this stock? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

};

export default StockData;
