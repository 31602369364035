import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, Typography, TableContainer, TableHead,
  TableRow, Paper, IconButton, Menu, MenuItem, TablePagination, Tooltip, TextField
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import SettlementDialog from './SettlementDialog';

const PaymentTermsDialog = ({ data, onUpdateClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [settlementOpen, setSettlementOpen] = useState(false);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [additionalCharges, setAdditionalCharges] = useState({}); // State to store manually updated additional charges
  const apiURL = process.env.REACT_APP_API_URL;

  const handleClick = (event, term) => {
    setAnchorEl(event.currentTarget);
    setSelectedTerm(term);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedTerm(null);
  };

  const handleUpdate = () => {
    onUpdateClick(selectedTerm);
    handleCloseMenu();
  };

  const handleSendWhatsapp = async () => {
    const whatsappData = {
      customerName: data.customerName,
      mobileNumber: data.mobileNumber,
      NoPlate: data.NoPlate,
      dueDate: selectedTerm.dueDate,
      amountDue: selectedTerm.amountDue
    };

    try {
      const response = await axios.post(`${apiURL}/paymenterms/sendWhatsapp`, whatsappData);
      if (response.data.whatsappUrl) {
        window.open(response.data.whatsappUrl, '_blank');
      } else {
        alert('Failed to generate WhatsApp message URL.');
      }
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
      alert('Failed to send WhatsApp message.');
    }

    handleCloseMenu();
  };

  const handleSettlementOpen = () => {
    setSettlementOpen(true);
    setSelectedTerms([]); // Reset selected terms
    handleCloseMenu();
  };

  const handleSettlementClose = () => {
    setSettlementOpen(false);
  };

  const handleSelectTerm = (termId) => {
    setSelectedTerms(prevSelected =>
      prevSelected.includes(termId) ? prevSelected.filter(id => id !== termId) : [...prevSelected, termId]
    );
  };

  const handleSettlementConfirm = async () => {
    const updatedTerms = data.paymentTerms.map(term => {
      if (selectedTerms.includes(term._id)) {
        const settlementAmount = calculateSettlementAmount(); // Use interest-free amount
        return { ...term, balanceAmount: settlementAmount };
      }
      return term;
    });

    try {
      await axios.put(`${apiURL}/paymenterms/bulk-update`, { paymentTerms: updatedTerms });
     
    } catch (error) {
      console.error('Error during settlement:', error);
   
    }

    handleSettlementClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'paid':
        return <CheckBoxIcon style={{ color: 'green' }} />;
      case 'pending':
        return <CheckBoxOutlineBlankIcon style={{ color: 'gray' }} />;
      case 'overdue':
        return <CloseIcon style={{ color: 'orange' }} />;
      default:
        return null;
    }
  };


//   const calculateAdditionalCharge = (dueDate) => {
//     const totalInterestAmount = data.totalLoanPaymentAmount - data.totalFinancingAmount;
//     const monthlyInterestValue = totalInterestAmount / data.balanceTenure;
//     const dailyDueCharge = monthlyInterestValue / 30;

//     const today = new Date();
//     const paymentDueDate = new Date(dueDate);

//     // Calculate the exact difference in full days only
//     const timeDifference = today.getTime() - paymentDueDate.getTime();
//     const overdueDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Using floor to count only full days

//     if (overdueDays > 0) {
//         const additionalCharge = overdueDays * dailyDueCharge;
//         if (overdueDays >= 90) {
//             return additionalCharge + 50; // Blocked account additional charge with RM50 unblock fee
//         }
//         return additionalCharge;
//     }
//     return 0;
// };
const calculateAdditionalCharge = (dueDate) => {
  // Calculate the total interest amount
  const totalInterestAmount = data.totalLoanPaymentAmount - data.totalFinancingAmount;
  console.log("Total Interest Amount:", totalInterestAmount);

  // Calculate the monthly interest value
  const monthlyInterestValue = totalInterestAmount / data.balanceTenure;
  console.log("Monthly Interest Value:", monthlyInterestValue);

  // Calculate the daily due charge
  const dailyDueCharge = monthlyInterestValue / 30;
  console.log("Daily Due Charge:", dailyDueCharge);

  const today = new Date();
  const paymentDueDate = new Date(dueDate);
  console.log("Today's Date:", today);
  console.log("Payment Due Date:", paymentDueDate);

  // Calculate the exact difference in full days only
  const timeDifference = today.getTime() - paymentDueDate.getTime();
  console.log("Time Difference (ms):", timeDifference);

  const overdueDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  console.log("Overdue Days:", overdueDays);

  if (overdueDays > 0) {
      const additionalCharge = overdueDays * dailyDueCharge;
      console.log("Additional Charge before Blocked Account Check:", additionalCharge);

      if (overdueDays >= 90) {
          console.log("Account Blocked - Additional RM50 charge applied");
          return additionalCharge + 50; // Blocked account additional charge with RM50 unblock fee
      }
      return additionalCharge;
  }

  console.log("No overdue - No Additional Charge");
  return 0;
};


  const handleAdditionalChargeChange = (event, termId) => {
    setAdditionalCharges({
      ...additionalCharges,
      [termId]: event.target.value,
    });
  };

  // Calculate the interest-free settlement amount per month
  const calculateSettlementAmount = () => {
    return data.totalFinancingAmount / data.balanceTenure;
  };

  const unpaidTerms = data.paymentTerms.filter(term => term.status === 'pending' || term.status === 'unpaid');

  return (
    <div>
      <Typography variant="h6" style={{ marginLeft: '10px' }}>{data.customerName} / Payment Terms Details</Typography>
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Monthly Amount (RM)</TableCell>
              <TableCell>Paid Amount (RM)</TableCell>
              <TableCell>Balance Amount (RM)</TableCell>
              <TableCell>Additional Charge (RM)</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.paymentTerms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => {
              const initialAdditionalCharge = calculateAdditionalCharge(term.dueDate);
              const additionalCharge = additionalCharges[term._id] || initialAdditionalCharge;
              const paymentDueDate = new Date(term.dueDate);
              const today = new Date();
              const overdueDays = Math.ceil((today - paymentDueDate) / (1000 * 3600 * 24));

              // Define row style based on overdue days
              const rowStyle = overdueDays >= 1 && overdueDays <= 89
                ? { backgroundColor: '#fdcf7b' } // Orange for 1-89 days overdue
                : overdueDays >= 90
                  ? { backgroundColor: '#f9b2b2' } // Red for 90+ days overdue
                  : { backgroundColor: 'transparent' }; // Default color for non-overdue

              return (
                <Tooltip title={overdueDays >= 90 ? 'Account blocked due to overdue.' : ''} arrow key={term._id}>
                  <TableRow style={rowStyle}> {/* Apply rowStyle here */}
                    <TableCell>{term.installmentNumber}</TableCell>
                    <TableCell>{paymentDueDate.toLocaleDateString()}</TableCell>
                    <TableCell>{term.monthlyInstalmentAmount.toFixed(2)}</TableCell>
                    <TableCell>{term.paidAmount?.toFixed(2)}</TableCell>
                    <TableCell>{term.balanceAmount?.toFixed(2)}</TableCell>
                    <TableCell>{additionalCharge}</TableCell>

                    {/* <TableCell>
                      <TextField
                        value={additionalCharge}
                        onChange={(event) => handleAdditionalChargeChange(event, term._id)}
                        inputProps={{ style: { textAlign: 'center' } }}
                      />
                    </TableCell> */}
                    <TableCell>{getStatusIcon(term.status)}</TableCell>
                    <TableCell>
                      <IconButton onClick={(event) => handleClick(event, term)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && selectedTerm === term)}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem onClick={handleUpdate}>Payment</MenuItem>
                        <MenuItem onClick={handleSendWhatsapp}>Send WhatsApp</MenuItem>
                        <MenuItem onClick={handleSettlementOpen}>Settlement</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              );
            })}
          </TableBody>

        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={data.paymentTerms.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Settlement Dialog */}
      <SettlementDialog
        open={settlementOpen}
        unpaidTerms={unpaidTerms}
        selectedTerms={selectedTerms}
        onSelectTerm={handleSelectTerm}
        onConfirmSettlement={handleSettlementConfirm}
        onClose={handleSettlementClose}
        calculateSettlementAmount={calculateSettlementAmount}
      />
    </div>
  );
};

export default PaymentTermsDialog;
