import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import axios from 'axios';

const UserDetails = ({ open, onClose, userId }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [monthlyData, setMonthlyData] = useState([]); // Monthly data state
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const apiBaseURL = process.env.REACT_APP_API_URL;

  // Fetch user details
  useEffect(() => {
    if (userId && open) {  // Only fetch when the dialog is open and userId is available
      const fetchUserDetails = async () => {
        try {
          const storedToken = localStorage.getItem('token');
          const response = await axios.get(`${apiBaseURL}/users/${userId}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${storedToken}`,
            },
          });
          setUserDetails(response.data);
        } catch (err) {
          setError('Failed to fetch user details.');
        }
      };
      fetchUserDetails();
    }
  }, [userId, open]);

  // Fetch monthly data
  useEffect(() => {
    if (userId && open) {  // Only fetch when the dialog is open and userId is available
      const fetchMonthlyData = async () => {
        try {
          const storedToken = localStorage.getItem('token');
          const response = await axios.get(
            `${apiBaseURL}/customerloans/my-loans/monthlyclaim?userId=${userId}&status=claim`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${storedToken}`,
            },
          }
          );

          // Debug the API response
          console.log(response.data);

          // Check if loansByMonth exists and is an object before transforming
          if (response.data && response.data.loansByMonth) {
            const transformedData = Object.entries(response.data.loansByMonth).map(([month, count]) => ({
              month,
              count
            }));
            setMonthlyData(transformedData);
          } else {
            setMonthlyData([]); // Set as an empty array if no data
          }
        } catch (err) {
          setError('Failed to fetch monthly data.');
        } finally {
          setLoading(false);
        }
      };
      fetchMonthlyData();
    }
  }, [userId, open]);

  // Display loading state
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>User Details</DialogTitle>
      <DialogContent>
        {userDetails && (
          <DialogContentText>
            <strong>ID:</strong> {userDetails._id}<br />
            <strong>Username:</strong> {userDetails.username}<br />
            <strong>Email:</strong> {userDetails.email}<br />
            <strong>Role:</strong> {userDetails.role ? userDetails.role.name : 'N/A'}<br />
            <strong>Outlet:</strong> {userDetails.outletId ? userDetails.outletId.name : 'N/A'}<br />
            <strong>Created At:</strong> {new Date(userDetails.createdAt).toLocaleDateString()}<br />
            <strong>Updated At:</strong> {new Date(userDetails.updatedAt).toLocaleDateString()}<br />
          </DialogContentText>
        )}
        <Typography variant="h6" style={{ marginTop: '20px' }}>Monthly Data</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell>Loan Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {monthlyData.length > 0 ? (
              monthlyData.map((monthData, index) => (
                <TableRow key={index}>
                  <TableCell>{monthData.month}</TableCell>
                  <TableCell>{monthData.count}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2}>No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDetails;
