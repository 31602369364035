import React, { useState } from 'react';
import {
  Box, Accordion, AccordionSummary,
  AccordionDetails, TextField, Button, Typography, MenuItem,
  Container
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import './new.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import UploadImageCash from '../../components/UploadImageCash';
import { MobileNumberMask, NRICNoMask } from './MobileNumberMask';
import { makeStyles } from '@mui/styles';
import { jsPDF } from 'jspdf';

const useStyles = makeStyles({
  customButton: {
    backgroundColor: '#037b7d',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2c9294',
    },
  },
});

const NewCustomercash = () => {
  const [customerId, setCustomerId] = useState(null);
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false); // Track submission success
  const classes = useStyles();
  const apiURL = process.env.REACT_APP_API_URL;

  const handleDownloadReceipt = () => {
    const doc = new jsPDF();
  
    // Outlet Information (Top Left)
    doc.setFontSize(12);
    doc.text("Outlet Information", 10, 10);
    doc.text("Name: Demo Outlet 02", 10, 20);
    doc.text("Email: demooutlet@example.com", 10, 30);
    doc.text("Phone: 0123456789", 10, 40);
    doc.text("Location: Demo Outlet", 10, 50);
  
    // Dynamic Customer Information (Top Right)
    doc.text("Customer Information", 140, 10);
    doc.text(`Name: ${customerDetails.name || 'N/A'}`, 140, 20);
    doc.text(`Mobile: ${customerDetails.mobileNumber || 'N/A'}`, 10, 30);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 140, 40);
  
    // Dynamic Item Details Table Header (Middle)
    doc.setFillColor(31, 119, 180); // Table header color
    doc.rect(10, 60, 190, 10, 'F'); // Header background
    doc.setTextColor(255, 255, 255); // White text for header
    doc.text("ID", 15, 67);
    doc.text("Due Date", 55, 67);
    doc.text("Description", 100, 67);
    doc.text("Amount", 160, 67);
  
    // Reset text color to black for table content
    doc.setTextColor(0, 0, 0);
  
    // Dynamic Bike Details Row
    const bikeDetails = {
      id: "B123",  // This could be dynamically set if you have an ID
      dueDate: "12/4/2024",  // Could be dynamically set based on your application data
      description: `${employmentDetails.stockModel || 'Model Unknown'}, ${employmentDetails.stockColour || 'Color Unknown'}, Engine ${employmentDetails.condition || 'Condition Unknown'}`,
      amount: employmentDetails.salesPrice || '0.00'
    };
  
    // Populate Item row with bike details
    doc.text(bikeDetails.id, 15, 77);
    doc.text(bikeDetails.dueDate, 55, 77);
    doc.text(bikeDetails.description, 100, 77);
    doc.text(`RM ${parseFloat(bikeDetails.amount).toFixed(2)}`, 160, 77);
  
    // Payment Summary (Right Middle)
    const totalAmount = parseFloat(bikeDetails.amount) || 0;
    const sst = totalAmount * 0.1;
    const subtotal = totalAmount + sst;
  
    doc.setFontSize(12);
    doc.text("Total: RM " + totalAmount.toFixed(2), 140, 100);
    doc.text("SST (10%): RM " + sst.toFixed(2), 140, 110);
    doc.text("Subtotal: RM " + subtotal.toFixed(2), 140, 120);
    doc.text("Adjustment: RM 0.00", 140, 130);
    doc.text("Grand Total: RM " + subtotal.toFixed(2), 140, 140);
  
    // Cash Payment and Status (Bottom Left)
    doc.text("Cash Payment", 10, 160);
    doc.text("RM", 40, 160);
    doc.text("Balance", 10, 170);
    doc.text("RM 0", 40, 170);
    doc.text("Status", 10, 180);
    doc.text("Paid", 40, 180);
  
    // Save the PDF
    doc.save("payment_receipt.pdf");
  };
  
  const [customerDetails, setCustomerDetails] = useState({
    cashType: '',
    name: '',
    nricNo: '',
    mobileNumber: '',
    email: '',
    address: '',
  });

  const [employmentDetails, setEmploymentDetails] = useState({
    condition: '',
    stockManufacturing: '',
    stockModel: '',
    stockVariants: '',
    stockColour: '',
    salesPrice: '',
  });

  const [attachmentLoan, setAttachmentLoan] = useState({
    nric: { front: '', back: '' },
    licence: { front: '', back: '' },
  });

  const [saveStatus, setSaveStatus] = useState({
    customerDetails: { disabled: false, color: 'primary' },
    employmentDetails: { disabled: false, color: 'primary' },
    attachmentLoan: { disabled: false, color: 'primary' },
  });

  const handleInputChange = (section, field, value) => {
    switch (section) {
      case 'customerDetails':
        setCustomerDetails({ ...customerDetails, [field]: value });
        break;
      case 'employmentDetails':
        setEmploymentDetails({ ...employmentDetails, [field]: value });
        break;
      case 'attachmentLoan':
        if (['nric', 'licence'].includes(field)) {
          setAttachmentLoan({
            ...attachmentLoan,
            [field]: { ...attachmentLoan[field], ...value },
          });
        } else {
          setAttachmentLoan({ ...attachmentLoan, [field]: value });
        }
        break;
      default:
        break;
    }
  };

  const handleSave = async (section) => {
    try {
      let data;
      let url;
      switch (section) {
        case 'customerDetails':
          data = customerDetails;
          url = `${apiURL}/customercashs`;
          const storedToken = localStorage.getItem('token');
          const customerResponse = await axios.post(url, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${storedToken}`,
            },
          });
          const newCustomer = customerResponse.data;
          setCustomerId(newCustomer._id);
          setSaveStatus({
            ...saveStatus,
            customerDetails: { disabled: true, color: 'warning' },
          });
          setIsSubmissionSuccessful(true); // Set to true after successful submission
          alert('Customer details saved successfully!');
          break;
        case 'employmentDetails':
          if (!customerId) {
            alert('Please save customer details first.');
            return;
          }
          data = { ...employmentDetails, customerId };
          url = `${apiURL}/employmentcashs`;
          break;
        case 'attachmentLoan':
          if (!customerId) {
            alert('Please save customer details first.');
            return;
          }
          data = { ...attachmentLoan, customerId };
          url = `${apiURL}/attachmentcashs`;
          break;
        default:
          return;
      }

      if (section !== 'customerDetails') {
        const storedToken = localStorage.getItem('token');
        const response = await axios.post(url, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        console.log(`${section} saved successfully:`, response.data);
        setSaveStatus({
          ...saveStatus,
          [section]: { disabled: true, color: 'warning' },
        });
        setIsSubmissionSuccessful(true); // Set to true for other sections as well
        alert(`${section.replace(/([A-Z])/g, ' $1')} saved successfully!`);
      }
    } catch (error) {
      console.error(`Error saving ${section}:`, error);
      alert(`Error saving ${section}: ${error.message}`);
    }
  };

  const handleUploadComplete = (uploadedFiles) => {
    handleInputChange('attachmentLoan', 'nric', {
      front: uploadedFiles.nricFront,
      back: uploadedFiles.nricBack,
    });
    handleInputChange('attachmentLoan', 'licence', {
      front: uploadedFiles.licenceFront,
      back: uploadedFiles.licenceBack,
    });
  };

  return (
    <div className="new">
      <div className="addtable">
        <Sidebar />
        <div className="newContainer">
          <Navbar />
          <Container component="main" maxWidth="lg">
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ px: 2 }}>
              <Box width="100%" maxWidth="800px" p={3} boxShadow={3} borderRadius={2} bgcolor="background.paper">
                <h1>New Customer Cash</h1>
                <div className="formContainer">

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Customer Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <TextField
                          label="Cash Type"
                          value={customerDetails.cashType}
                          onChange={(e) => handleInputChange('customerDetails', 'cashType', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        >
                          <MenuItem value="SLH">SLH</MenuItem>
                          <MenuItem value="AEON">AEON</MenuItem>
                          <MenuItem value="JOL">JOL</MenuItem>
                        </TextField>
                        <TextField
                          label="Name"
                          value={customerDetails.name}
                          onChange={(e) => handleInputChange('customerDetails', 'name', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="NRIC No"
                          value={customerDetails.nricNo}
                          onChange={(e) => handleInputChange('customerDetails', 'nricNo', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                          InputProps={{
                            inputComponent: NRICNoMask,
                          }}
                        />
                        <TextField
                          label="Mobile Number"
                          value={customerDetails.mobileNumber}
                          onChange={(e) => handleInputChange('customerDetails', 'mobileNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                          InputProps={{
                            inputComponent: MobileNumberMask,
                          }}
                        />
                        <TextField
                          label="Email"
                          value={customerDetails.email}
                          onChange={(e) => handleInputChange('customerDetails', 'email', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="Address"
                          value={customerDetails.address}
                          onChange={(e) => handleInputChange('customerDetails', 'address', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <Button
                          type="button"
                          variant="contained"
                          className={classes.customButton}
                          color={saveStatus.customerDetails.color}
                          disabled={saveStatus.customerDetails.disabled}
                          onClick={() => handleSave('customerDetails')}
                        >
                          Save Customer Details
                        </Button>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Attachment Cash</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <UploadImageCash customerId={customerId} onComplete={handleUploadComplete} />
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Order Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <TextField
                          label="Condition"
                          value={employmentDetails.condition}
                          onChange={(e) => handleInputChange('employmentDetails', 'condition', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Manufacturing"
                          value={employmentDetails.stockManufacturing}
                          onChange={(e) => handleInputChange('employmentDetails', 'stockManufacturing', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Model"
                          value={employmentDetails.stockModel}
                          onChange={(e) => handleInputChange('employmentDetails', 'stockModel', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Variants"
                          value={employmentDetails.stockVariants}
                          onChange={(e) => handleInputChange('employmentDetails', 'stockVariants', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Colour"
                          value={employmentDetails.stockColour}
                          onChange={(e) => handleInputChange('employmentDetails', 'stockColour', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Price"
                          value={employmentDetails.salesPrice}
                          onChange={(e) => handleInputChange('employmentDetails', 'salesPrice', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <Button
                          type="button"
                          variant="contained"
                          className={classes.customButton}
                          color={saveStatus.employmentDetails.color}
                          disabled={saveStatus.employmentDetails.disabled}
                          onClick={() => handleSave('employmentDetails')}
                        >
                          Save Order Details
                        </Button>
                        {isSubmissionSuccessful && (
                          <Button
                            type="button"
                            variant="contained"
                            className={classes.customButton}
                            onClick={handleDownloadReceipt}
                          >
                            Download Receipt
                          </Button>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>

                </div>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default NewCustomercash;
