import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';

import HandcashData from '../../components/handcashdata/HandcashData';
import HandcashTableData from '../../components/handcashdata/HandcashTableData';

const HandcashTable = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
        {/* <List />
         */}
         {/* <List  /> */}
         <HandcashTableData />
      </div>
    </div>
  );
};

export default HandcashTable;
