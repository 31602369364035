import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddBikeModelData = () => {
  const navigate = useNavigate();
  const [bikeModel, setBikeModel] = useState({
    manufacturing: '',
    model: '',
    variants: '',
    color: '',
    price: 0,
    cost: 0
  });
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const apiURL = process.env.REACT_APP_API_URL;

  const handleInputChange = (field, value) => {
    setBikeModel({ ...bikeModel, [field]: value });
  };

  const handleSaveBikeModel = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        throw new Error('No token found, authorization required');
      }

      const response = await axios.post(`${apiURL}/bikemodels`, bikeModel, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });

      console.log('Bike model added:', response.data);
      setSnackbarMessage('Bike model added successfully!');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error adding bike model:', error);
      setError('Failed to add bike model. Please try again.');
    }
  };


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    navigate('/bikemodels'); // Redirect after Snackbar closes
  };

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ px: 2 }}
    >
      <Box
        width="100%"
        maxWidth="800px"
        p={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
      >
        <Typography variant="h4" align="center" gutterBottom>
          Add New Bike Model
        </Typography>
        <form>
          {/* <TextField
            label="Manufacturing"
            value={bikeModel.manufacturing}
            onChange={(e) => handleInputChange('manufacturing', e.target.value)}
            fullWidth
            margin="normal"
          /> */}
          <TextField
            label="Manufacturing"
            value={bikeModel.manufacturing}
            onChange={(e) => handleInputChange('manufacturing', e.target.value.toUpperCase())}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Model"
            value={bikeModel.model}
            onChange={(e) => handleInputChange('model', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Variants"

            value={bikeModel.variants}
            onChange={(e) => handleInputChange('variants', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Color"
            value={bikeModel.color}
            onChange={(e) => handleInputChange('color', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Price"
            type="number"
            value={bikeModel.price}
            onChange={(e) => handleInputChange('price', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Cost"
            type="number"
            value={bikeModel.cost}
            onChange={(e) => handleInputChange('cost', e.target.value)}
            fullWidth
            margin="normal"
          />
        </form>
        <Box display="flex" justifyContent="center" mt={3}>
          <Button onClick={handleSaveBikeModel} variant="contained" style={{ backgroundColor: '#2c9294' }}>
            Add Bike Model
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ top: 0 }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddBikeModelData;
