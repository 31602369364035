import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Box,
  Grid
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ReminderData = () => {
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // Add state for search term
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchReminders = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/financeloans/payments/reminder`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
        setReminders(response.data.data); // Update to access the `data` array
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReminders();
  }, [apiURL]);

  // Filter reminders based on the search term
  const filteredReminders = reminders.filter((reminder) =>
    reminder.customerLoanName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (reminder.noPlate && reminder.noPlate.toLowerCase().includes(searchTerm.toLowerCase())) ||
    reminder.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClick = (reminderId) => {
    navigate(`/paymenterms/${reminderId}`);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="10px"
        marginTop="10px"
      >
        <TextField
          label="Search by Name, No Plate, or Mobile"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update the search term on change
          style={{ marginLeft: '10px', width: '300px' }} // Adjust styling as necessary
        />
      </Box>

      <Grid container spacing={2}>
        {filteredReminders.map((reminder) => (
          <Grid item xs={12} md={6} lg={3} key={reminder.dueDate}>
            <Card style={{ backgroundColor: 'cornsilk', padding: '10px' }}>
              <CardContent>
                <Typography
                  variant="h6"
                  color="primary"
                  gutterBottom
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleClick(reminder.id)} // Navigate on click
                >
                  {reminder.customerLoanName}
                </Typography>
                {reminder.noPlate && (
                  <Typography color="textSecondary">
                    No Plate: {reminder.noPlate}
                  </Typography>
                )}
                <Typography color="textSecondary">
                  Mobile: {reminder.mobileNumber}
                </Typography>
                <Typography color="textSecondary">
                  Due Amount: RM{reminder.dueAmount} 
                </Typography>
                <Typography color="textSecondary">
                  Due Date: {new Date(reminder.dueDate).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ReminderData;
