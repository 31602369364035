import React, { useState } from "react";
import { TextField, Button, Typography, Grid, MenuItem } from "@mui/material";

const PlannerForm = () => {
  const [totalCreditSales, setTotalCreditSales] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [downPaymentsReceived, setDownPaymentsReceived] = useState(0);
  const [cost, setCost] = useState(0);
  const [amountLoanToCustomer, setAmountLoanToCustomer] = useState(0);
  const [accumulatedLC, setAccumulatedLC] = useState(0);
  const [loanDisbursements, setLoanDisbursements] = useState(0);
  const [accumulatedLD, setAccumulatedLD] = useState(0);
  const [months, setMonths] = useState(36); // Default 36 months
  const [bInstallmentsCollected, setBInstallmentsCollected] = useState(0);
  const [currentCashDeficit, setCurrentCashDeficit] = useState(0);
  const [currentBudgetCashDeficit, etCurrentBudgetCashDeficit] = useState(0);
  const [accCashDeficit, setAccCashDeficit] = useState(0);
  const [actualInPercentage, setActualInPercentage] = useState(0);
  const [actualInCollected, setActualInCollected] = useState(0);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());

  const calculateValues = () => {
    // Calculate Down Payments Received
    const calculatedDownPaymentsReceived =
      (totalCreditSales * percentage) / 100;

    // Calculate Loan Disbursements
    const calculatedLoanDisbursements =
      totalCreditSales - calculatedDownPaymentsReceived;

    // Calculate Amount Loan to Customer
    const calculatedLoanToCustomer = cost - calculatedDownPaymentsReceived;

    // Correct formula for B. Installments Collected
    const calculatedInstallmentsCollected =
      (calculatedLoanDisbursements + 0.30) / months;

    // Calculate Actual In. Collected dynamically
    const calculatedActualInCollected =
      (calculatedInstallmentsCollected * actualInPercentage) / 100;

    // Accumulated LC (Add to the previous accumulated LC)
    const calculatedAccumulatedLC = accumulatedLC + calculatedLoanToCustomer;

    // Accumulated LD (Add to the previous accumulated LD)
    const calculatedAccumulatedLD = accumulatedLD + calculatedLoanDisbursements;

    // Current Cash Deficit
    const calculatedCurrentCashDeficit =
      calculatedLoanToCustomer - calculatedInstallmentsCollected;

    // Current Budget Cash Deficit
    const calculatedBudgetCashDeficit =
      calculatedLoanToCustomer - calculatedActualInCollected;

    // Accumulated Cash Deficit
    const calculatedAccCashDeficit =
      accCashDeficit + calculatedCurrentCashDeficit;

    // Update State
    setDownPaymentsReceived(calculatedDownPaymentsReceived);
    setLoanDisbursements(calculatedLoanDisbursements);
    setAmountLoanToCustomer(calculatedLoanToCustomer);
    setBInstallmentsCollected(calculatedInstallmentsCollected);
    setAccumulatedLC(calculatedAccumulatedLC);
    setAccumulatedLD(calculatedAccumulatedLD);
    setCurrentCashDeficit(calculatedCurrentCashDeficit);
    etCurrentBudgetCashDeficit(calculatedBudgetCashDeficit);
    setAccCashDeficit(calculatedAccCashDeficit);
    setActualInCollected(calculatedActualInCollected);
  };

  const handleSubmit = async () => {
    const payload = {
      month,
      year,
      totalCreditSales,
      percentage,
      downPaymentsReceived,
      cost,
      amountLoanToCustomer,
      accumulatedLC,
      loanDisbursements,
      accumulatedLD,
      months,
      bInstallmentsCollected,
      currentCashDeficit,
      currentBudgetCashDeficit,
      accCashDeficit,
      actualInPercentage,
      actualInCollected,
    };

    try {
      const storedToken = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/planners`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("API Response:", result);
      alert("Data submitted successfully!");
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to submit data. Please try again.");
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "400px", margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Monthly Planner Form
      </Typography>
      <Grid container spacing={2} direction="column">
        {/* Month Selection */}
        <Grid item xs={12}>
          <TextField
            select
            label="Month"
            fullWidth
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((m) => (
              <MenuItem key={m} value={m}>
                {m}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Year Selection */}
        <Grid item xs={12}>
          <TextField
            label="Year"
            type="number"
            fullWidth
            value={year}
            onChange={(e) => setYear(Number(e.target.value))}
          />
        </Grid>

        {/* Total Credit Sales */}
        {/* <Grid item>
          <TextField
            label="Total Credit Sales"
            type="number"
            fullWidth
            value={totalCreditSales}
            onChange={(e) => setTotalCreditSales(Number(e.target.value))}
          />

        </Grid> */}
        <Grid item>
          <TextField
            label="Total Credit Sales"
            type="number"
            fullWidth
            value={totalCreditSales}
            onChange={(e) => setTotalCreditSales(Number(e.target.value))}
            required
            error={totalCreditSales <= 0}
            helperText={totalCreditSales <= 0 ? "This field is required and must be greater than 0" : ""}
          />
        </Grid>

        {/* Percentage */}
        {/* <Grid item>
          <TextField
            label="Percentage (%)"
            type="number"
            fullWidth
            value={percentage}
            onChange={(e) => setPercentage(Number(e.target.value))}
          />
        </Grid> */}
        <Grid item>
          <TextField
            label="Percentage (%)"
            type="number"
            fullWidth
            value={percentage}
            onChange={(e) => setPercentage(Number(e.target.value))}
            required
            error={percentage <= 0}
            helperText={percentage <= 0 ? "This field is required and must be greater than 0" : ""}
          />
        </Grid>

        {/* Down Payments Received */}
        <Grid item>
          <TextField
            label="Down Payments Received"
            value={`RM${downPaymentsReceived.toFixed(2)}`}
            fullWidth
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {/* Cost */}
        <Grid item>
          <TextField
            label="Cost"
            type="number"
            fullWidth
            value={cost}
            onChange={(e) => setCost(Number(e.target.value))}
            required
            error={cost <= 0}
            helperText={cost <= 0 ? "This field is required and must be greater than 0" : ""}
          />
        </Grid>

        {/* Amount Loan to Customer */}
        <Grid item>
          <TextField
            label="Amount Loan to Customer"
            value={`RM${amountLoanToCustomer.toFixed(2)}`}
            fullWidth
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {/* Accumulated LC */}
        <Grid item>
          <TextField
            label="Accumulated LC"
            value={`RM${accumulatedLC.toFixed(2)}`}
            fullWidth
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {/* Loan Disbursements */}
        <Grid item>
          <TextField
            label="Loan Disbursements"
            value={`RM${loanDisbursements.toFixed(2)}`}
            fullWidth
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {/* Months */}
        <Grid item>
          <TextField
            label="Months (e.g., 36)"
            type="number"
            fullWidth
            value={months}
            onChange={(e) => setMonths(Number(e.target.value))}
          />
        </Grid>

        {/* B. Installments Collected */}
        <Grid item>
          <TextField
            label="B. Installments Collected"
            value={`RM${bInstallmentsCollected.toFixed(2)}`}
            fullWidth
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {/* Current Cash Deficit */}
        <Grid item>
          <TextField
            label="Current Cash Deficit"
            value={`RM${currentCashDeficit.toFixed(2)}`}
            fullWidth
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {/* Current Budget Cash Deficit */}
        <Grid item>
          <TextField
            label="Current Budget Cash Deficit"
            value={`RM${currentBudgetCashDeficit.toFixed(2)}`}
            fullWidth
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {/* Actual In Percentage */}
        {/* <Grid item>
          <TextField
            label="Actual In. Percentage (%)"
            type="number"
            fullWidth
            value={actualInPercentage}
            onChange={(e) => setActualInPercentage(Number(e.target.value))}
          />
        </Grid> */}
        <Grid item>
          <TextField
            label="Actual In. Percentage (%)"
            type="number"
            fullWidth
            value={actualInPercentage}
            onChange={(e) => setActualInPercentage(Number(e.target.value))}
            required
            error={actualInPercentage <= 0}
            helperText={actualInPercentage <= 0 ? "This field is required and must be greater than 0" : ""}
          />
        </Grid>

        {/* Actual In Collected */}
        <Grid item>
          <TextField
            label="Actual In. Collected"
            value={`RM${actualInCollected.toFixed(2)}`}
            fullWidth
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {/* Buttons */}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={calculateValues}
          >
            Calculate
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PlannerForm;
