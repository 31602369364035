import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  TablePagination,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SalaryTable = () => {
  const [salaryData, setSalaryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const apiURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSalaryData = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage

        if (!token) {
          console.error('Token not found!');
          return;
        }

        const response = await axios.get(`${apiURL}/salaries`, {
          headers: {
            Authorization: `Bearer ${token}`, // Attach token in Authorization header
          },
        });

        setSalaryData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching salary data:', error.response?.data || error.message);
        setLoading(false);
      }
    };

    fetchSalaryData();
  }, []);

  // Handle pagination changes
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box padding={0}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={0}>
        <Typography variant="h4" gutterBottom>
          Employee Salary
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/salary/AddSalary')} // Navigate to Add Salary route
        >
          Add Salary
        </Button>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>#</strong></TableCell>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>NIC</strong></TableCell>
                  <TableCell><strong>Position</strong></TableCell>
                  <TableCell><strong>Income Details</strong></TableCell>
                  <TableCell><strong>EPF Staff(%)</strong></TableCell>
              
                  <TableCell><strong>SOCSO Staff(%)</strong></TableCell>
                
                  <TableCell><strong>PCB(%)</strong></TableCell>
                  <TableCell><strong>EIS(%)</strong></TableCell>
                  <TableCell><strong>Advance</strong></TableCell>
                  <TableCell><strong>Staff Loan </strong></TableCell>
                  <TableCell><strong>Net Salary </strong></TableCell>
                  <TableCell><strong>Created Date</strong></TableCell>
                  <TableCell><strong>Actions</strong></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
  {salaryData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Paginate data
    .map((record, index) => (
      <TableRow key={record._id}>
        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
        <TableCell>{record.basicInfo?.name || 'N/A'}</TableCell>
        <TableCell>{record.basicInfo?.NIC || 'N/A'}</TableCell>
        <TableCell>{record.basicInfo?.position || 'N/A'}</TableCell>
        <TableCell>
          {record.incomeDetails.map((income, idx) => (
            <div key={idx}>
              <strong>{income.label}:</strong> {income.amount.toFixed(2)}
              {income.epf ? ' (EPF)' : ''}
            </div>
          ))}
        </TableCell>
        <TableCell>{record.epfEmployeeRate || 'N/A'}</TableCell>
        
        <TableCell>{record.socsoEmployeeRate || 'N/A'}</TableCell>
       
        <TableCell>{record.pcbRate || 'N/A'}</TableCell>
        <TableCell>{record.eisRate || 'N/A'}</TableCell>
        <TableCell>{record.advanceDeduction.toFixed(2)}</TableCell>
        <TableCell>{record.staffLoanDeduction.toFixed(2)}</TableCell>
        <TableCell>{record.netSalary.toFixed(2)}</TableCell>
        <TableCell>{new Date(record.createdAt).toLocaleString()}</TableCell>
        <TableCell>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => navigate(`/salary/${record._id}`)} // Navigate to Update Salary route
          >
            Print
          </Button>
        </TableCell>
      </TableRow>
    ))}
</TableBody>

            </Table>
          </TableContainer>
          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={salaryData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default SalaryTable;
