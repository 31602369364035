import React, { useState } from 'react';
import AWS from 'aws-sdk';
import axios from 'axios';
import { Button, Typography } from '@mui/material';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_REGION;
const API_URL = process.env.REACT_APP_API_URL;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: REGION,
});

const s3 = new AWS.S3();

const UploadImageCash = ({ customerId }) => {
  const [files, setFiles] = useState({});
  const [previews, setPreviews] = useState({});
  const [uploading, setUploading] = useState(false);
  const [fileUrls, setFileUrls] = useState({});
  const [saveStatus, setSaveStatus] = useState({
    disabled: false,
    color: 'primary',
  });

  const handleFileInput = (e) => {
    const { name, files } = e.target;
    const fileList = Array.from(files);

    setFiles((prevState) => ({
      ...prevState,
      [name]: fileList,
    }));

    const previewUrls = fileList.map((file) => URL.createObjectURL(file));
    setPreviews((prevState) => ({
      ...prevState,
      [name]: previewUrls,
    }));
  };

  const uploadFiles = async () => {
    setUploading(true);

    const uploadPromises = Object.keys(files).flatMap((key) =>
      files[key].map(async (file) => {
        const params = {
          Bucket: S3_BUCKET,
          Key: `customerLoans/${Date.now()}_${file.name}`,
          Body: file,
          ContentType: file.type,
        };

        try {
          const data = await s3.upload(params).promise();
          return { [key]: data.Location };
        } catch (err) {
          console.error('Error uploading file:', err);
          alert('Error uploading file. Please try again.');
          return null;
        }
      })
    );

    const uploadResults = await Promise.all(uploadPromises);
    const newFileUrls = uploadResults.reduce((acc, result) => {
      if (result) {
        const [key, value] = Object.entries(result)[0];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(value);
      }
      return acc;
    }, {});

    setFileUrls(newFileUrls);
    setUploading(false);

    if (Object.keys(newFileUrls).length === Object.keys(files).length) {
      try {
        const payload = {
          customerId,
          nric: {
            front: newFileUrls.nricFront ? newFileUrls.nricFront[0] : '',
            back: newFileUrls.nricBack ? newFileUrls.nricBack[0] : '',
          },
          licence: {
            front: newFileUrls.licenceFront ? newFileUrls.licenceFront[0] : '',
            back: newFileUrls.licenceBack ? newFileUrls.licenceBack[0] : '',
          },
          incomeStatement: newFileUrls.incomeStatement || [],
          salarySlip: newFileUrls.salarySlip || [],
          epfStatement: newFileUrls.epfStatement || [],
          utilitiesBill: newFileUrls.utilitiesBill || [],
          ctosReport: newFileUrls.ctosReport ? newFileUrls.ctosReport[0] : '',
        };
        const storedToken = localStorage.getItem('token');
        const response = await axios.post(`${API_URL}/attachmentcashs`, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        alert('Files uploaded and data saved successfully!');
        
        // Update saveStatus to disable the button after successful upload
        setSaveStatus({ disabled: true, color: 'success' });

      } catch (error) {
        console.error('Error posting data to API:', error);
        alert('Error saving data. Please try again.');
      }
    }
  };

  return (
    <div>
      <Typography variant="h6">Upload Documents</Typography>
      
      <div>
        <label>NRIC Front:</label>
        <input type="file" name="nricFront" onChange={handleFileInput} />
        {previews.nricFront && previews.nricFront.map((src, index) => <img key={index} src={src} alt="Preview" />)}
      </div>
      
      <div>
        <label>NRIC Back:</label>
        <input type="file" name="nricBack" onChange={handleFileInput} />
        {previews.nricBack && previews.nricBack.map((src, index) => <img key={index} src={src} alt="Preview" />)}
      </div>
      
      <div>
        <label>Licence Front:</label>
        <input type="file" name="licenceFront" onChange={handleFileInput} />
        {previews.licenceFront && previews.licenceFront.map((src, index) => <img key={index} src={src} alt="Preview" />)}
      </div>
      
      <div>
        <label>Licence Back:</label>
        <input type="file" name="licenceBack" onChange={handleFileInput} />
        {previews.licenceBack && previews.licenceBack.map((src, index) => <img key={index} src={src} alt="Preview" />)}
      </div>

      <Button
        variant="contained"
        color={saveStatus.color}
        onClick={uploadFiles}
        disabled={uploading || saveStatus.disabled}
      >
        {uploading ? 'Uploading...' : 'Save Attachment Loan'}
      </Button>
    </div>
  );
};

export default UploadImageCash;
