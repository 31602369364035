import React, { useEffect, useState } from 'react';
import {
  Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Menu, MenuItem, TablePagination, TableSortLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Datatable = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [monthlyData, setMonthlyData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('username');
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState(''); // New state for search input
  const apiURL = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await fetch(`${apiURL}/users`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setRows(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch user data.');
      }
    };
    fetchData();
  }, [apiURL]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(row._id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  const handleViewClick = async () => {
    if (selectedUserId) {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await fetch(`${apiURL}/users/${selectedUserId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        const data = await response.json();
        setSelectedUserDetails(data);

        const monthlyResponse = await axios.get(`${apiURL}/customerloans/my-loans/monthlyclaim?userId=${selectedUserId}&status=claim`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        const transformedData = Object.entries(monthlyResponse.data.loansByMonth).map(([month, count]) => ({
          month,
          count,
        }));
        setMonthlyData(transformedData);

        setOpenDialog(true);
      } catch (err) {
        console.error('Error fetching user details or monthly data:', err);
      }
    } else {
      console.error('User ID is undefined');
    }
    handleClose();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedUserDetails(null);
    setMonthlyData([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getNestedProperty = (row, propertyPath) => {
    return propertyPath.split('.').reduce((acc, part) => acc && acc[part], row);
  };

  const sortedRows = rows
    .filter((row) => row.username.toLowerCase().includes(searchText.toLowerCase())) // Filter by search text
    .slice()
    .sort((a, b) => {
      const valueA = getNestedProperty(a, orderBy);
      const valueB = getNestedProperty(b, orderBy);

      if (orderBy === 'createdAt' || orderBy === 'updatedAt') {
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      } else if (typeof valueA === 'string') {
        return order === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }
    });

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between" padding={"10px"}>
      <TextField
          label="Search by Username"
          variant="outlined"
          value={searchText}
          onChange={handleSearchChange}
        />
      
        {/* <Typography variant="h4" style={{ marginLeft: '10px' }}>User Management</Typography> */}
        <Button
          variant="contained"
          color="primary"
          href="/users/adduser"
          style={{ backgroundColor: '#2c9294', marginRight: '10px' }}
        >
          Add User
        </Button>
      </Box>


      {error ? (
        <div>Error: {error}</div>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === '_id'}
                    direction={orderBy === '_id' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, '_id')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'username'}
                    direction={orderBy === 'username' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'username')}
                  >
                    Username
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'email'}
                    direction={orderBy === 'email' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'email')}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'role.name'}
                    direction={orderBy === 'role.name' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'role.name')}
                  >
                    Role Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'outletId.name'}
                    direction={orderBy === 'outletId.name' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'outletId.name')}
                  >
                    Outlet Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'createdAt'}
                    direction={orderBy === 'createdAt' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'createdAt')}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'updatedAt'}
                    direction={orderBy === 'updatedAt' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'updatedAt')}
                  >
                    Updated At
                  </TableSortLabel>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row._id}</TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.role ? row.role.name : 'N/A'}</TableCell>
                  <TableCell>{row.outletId ? row.outletId.name : 'N/A'}</TableCell>
                  <TableCell>{formatDate(row.createdAt)}</TableCell>
                  <TableCell>{formatDate(row.updatedAt)}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleClick(event, row)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleViewClick}>View</MenuItem>
                      <MenuItem onClick={handleClose}>Delete</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={sortedRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth>
        <DialogTitle>User Details</DialogTitle>
        <DialogContent>
          {selectedUserDetails ? (
            <Box display="flex" flexDirection="column" gap="16px">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                padding="10px"
                border="1px solid lightgray"
                borderRadius="8px"
              >
                <Box display="flex" flexDirection="column" flex="1" marginRight="16px">
                  <DialogContentText>
                    <strong>ID:</strong> {selectedUserDetails._id}<br />
                    <strong>Username:</strong> {selectedUserDetails.username}<br />
                    <strong>Email:</strong> {selectedUserDetails.email}<br />
                  </DialogContentText>
                </Box>

                <Box display="flex" flexDirection="column" flex="1">
                  <DialogContentText>
                    <strong>Role:</strong> {selectedUserDetails.role ? selectedUserDetails.role.name : 'N/A'}<br />
                    <strong>Outlet:</strong> {selectedUserDetails.outletId ? selectedUserDetails.outletId.name : 'N/A'}<br />
                    <strong>Created At:</strong> {formatDate(selectedUserDetails.createdAt)}<br />
                  </DialogContentText>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                padding="10px"
                border="1px solid lightgray"
                borderRadius="8px"
              >
                <Typography variant="h6">Monthly Sales Claim</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Month</TableCell>
                      <TableCell>Customer Loan Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {monthlyData.length > 0 ? (
                      monthlyData.map((monthData, index) => (
                        <TableRow key={index}>
                          <TableCell>{monthData.month}</TableCell>
                          <TableCell>{monthData.count}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          ) : (
            <DialogContentText>Loading...</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Datatable;
