import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Divider,
  Box,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';

const RoleData = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false); // Dialog for editing/adding role
  const [roleName, setRoleName] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editRoleId, setEditRoleId] = useState(null);
  const apiURL = process.env.REACT_APP_API_URL;

  // Group permissions by model for easier display
  const groupedPermissions = {
    customerLoans: ["create_customerLoans", "read_customerLoans", "edit_customerLoans", "delete_customerLoans"],
    loansEmployment: ["create_loansEmployment", "read_loansEmployment", "edit_loansEmployment", "delete_loansEmployment"],
    loansReference: ["create_loansReference", "read_loansReference", "edit_loansReference", "delete_loansReference"],
    loansFinance: ["create_loansFinance", "read_loansFinance", "edit_loansFinance", "delete_loansFinance"],
    loansAttachment: ["create_loansAttachment", "read_loansAttachment", "edit_loansAttachment", "delete_loansAttachment"],
    customerCash: ["create_customerCash", "read_customerCash", "edit_customerCash", "delete_customerCash"],
    cashEmployment: ["create_cashEmployment", "read_cashEmployment", "edit_cashEmployment", "delete_cashEmployment"],
    cashAttachment: ["create_cashAttachment", "read_cashAttachment", "edit_cashAttachment", "delete_cashAttachment"],
    paymentTerms: ["create_paymentTerms", "read_paymentTerms", "edit_paymentTerms", "delete_paymentTerms"],
    score: ["create_score", "read_score", "edit_score", "delete_score"],
    bikeModels: ["create_bikeModels", "read_bikeModels", "edit_bikeModels", "delete_bikeModels"],
    users: ["create_users", "read_users", "edit_users", "delete_users"],
    outlets: ["create_outlets", "read_outlets", "edit_outlets", "delete_outlets"],
    role: ["create_role", "read_role", "edit_role", "delete_role"],
    stock: ["create_stock", "read_stock", "edit_stock", "delete_stock"],
    salary: ["create_salary", "read_salary", "edit_salary", "delete_salary"]
  };

  // Fetch roles function
  const fetchRoles = async () => {
    try {
      const storedToken = localStorage.getItem('token');

      const response = await axios.get(`${apiURL}/auth/role`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setRoles(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch roles when component mounts
  useEffect(() => {
    fetchRoles();
  }, [apiURL]);

  const handleEdit = (role) => {
    setIsEditing(true);
    setEditRoleId(role._id);
    setRoleName(role.name);
    setPermissions(role.permissions);
    setOpenDialog(true);
  };

  // const handleDelete = async (id) => {
  //   try {
  //     const storedToken = localStorage.getItem('token');
  //     await axios.delete(`${apiURL}/auth/role/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });
  //     fetchRoles(); // Fetch roles again after deleting
  //   } catch (err) {
  //     console.error('Error deleting role:', err);
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const roleData = {
  //     name: roleName,
  //     permissions: permissions,
  //   };

  //   try {
  //     if (isEditing) {
  //       await axios.put(`${apiURL}/auth/role/${editRoleId}`, roleData);
  //     } else {
  //       await axios.post(`${apiURL}/auth/role`, roleData);
  //     }

  //     setOpenDialog(false);
  //     fetchRoles(); // Refresh roles after adding/editing
  //   } catch (err) {
  //     console.error('Error creating/updating role:', err);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const storedToken = localStorage.getItem('token'); // Retrieve token from localStorage
    const roleData = {
      name: roleName,
      permissions: permissions,
    };
  
    try {
      const headers = {
        Authorization: `Bearer ${storedToken}`,
      };
  
      if (isEditing) {
        // PUT request to update the role
        await axios.put(`${apiURL}/auth/role/${editRoleId}`, roleData, { headers });
      } else {
        // POST request to create a new role
        await axios.post(`${apiURL}/auth/role`, roleData, { headers });
      }
  
      setOpenDialog(false);
      fetchRoles(); // Refresh roles after adding/editing
    } catch (err) {
      console.error('Error creating/updating role:', err);
    }
  };
  

  const handlePermissionChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter((perm) => perm !== value));
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" style={{ marginLeft: '10px' }}>Roles</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsEditing(false);
            setOpenDialog(true);
          }}
          style={{ backgroundColor: '#2c9294', marginRight: '10px' }}
        >
          Add Role
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Role Name</TableCell>
            <TableCell>Permissions</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((role, index) => (
            <TableRow key={role._id}>
              <TableCell>{page * rowsPerPage + index + 1}</TableCell>
              <TableCell>{role.name}</TableCell>
              <TableCell>{role.permissions.join(', ')}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(role)}>
                  <Edit color="primary" />
                </IconButton>
                {/* <IconButton onClick={() => handleDelete(role._id)}>
                  <Delete color="error" />
                </IconButton> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={roles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
      />

      {/* Dialog for adding/editing roles */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"  // Set dialog max width to small to reduce width
        fullWidth
      >
        <DialogTitle>{isEditing ? 'Edit Role' : 'Create Role'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Role Name"
              variant="outlined"
              fullWidth
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              required
              sx={{ marginBottom: 3 }}
            />

            <Typography variant="h6" gutterBottom>
              Select Permissions
            </Typography>

            <Box
              sx={{
                maxHeight: '550px', // Limit height and make it scrollable if necessary
                overflowY: 'auto',
                padding: '10px',
                marginLeft: '20px', // Push the permissions to the right
                border: '1px solid #ccc',
                borderRadius: '4px',
                marginBottom: 3,
                width: '95%%' // Reduce width
              }}
            >
              <Grid container spacing={2}>
                {Object.keys(groupedPermissions).map((model) => (
                  <React.Fragment key={model}>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" style={{ minWidth: '150px' }}>
                          {model.replace(/_/g, ' ')}
                        </Typography>
                        {groupedPermissions[model].map((perm) => (
                          <FormControlLabel
                            key={perm}
                            control={
                              <Checkbox
                                value={perm}
                                onChange={handlePermissionChange}
                                checked={permissions.includes(perm)}
                              />
                            }
                            label={perm.split('_')[0]} // Show action (create, read, etc.)
                          />
                        ))}
                      </Box>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>

            <Button variant="contained" color="primary" type="submit" fullWidth sx={{ marginTop: 3 }}>
              {isEditing ? 'Update Role' : 'Create Role'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RoleData;
