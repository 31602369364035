import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import './PlannerPage.scss'
import PlannerForm from './Planner'


const PlannerPageAdd = () => {
  return (
    <div className='planer'>
        <Sidebar />
        <div className='planerContainer'>
            <Navbar />
            <PlannerForm />

        </div>
        
    </div>
  )
}

export default PlannerPageAdd