import React, { useEffect, useState } from 'react';
import {
  Table, TableContainer, Paper, TablePagination, TableHead, TableRow, TableCell, TableBody, IconButton, Menu, MenuItem, CircularProgress, TextField, Box, TableSortLabel
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const FinanceLoan = () => {
  const [finances, setFinances] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input
  const [orderBy, setOrderBy] = useState('customerName'); // Default sorting by customerName
  const [orderDirection, setOrderDirection] = useState('asc'); // Default sorting direction

  const apiURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    axios.get(`${apiURL}/financeloans`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storedToken}`,
      }
    })
      .then(response => {
        const transformedFinances = response.data.map((finance) => ({
          ...finance,
          salePrice: parseFloat(finance.salePrice).toFixed(2),
          deposit: parseFloat(finance.deposit).toFixed(2),
          financingAmount: parseFloat(finance.financingAmount).toFixed(2),
          monthlyInstalmentAmount: parseFloat(finance.monthlyInstalmentAmount).toFixed(2),
          totalLoanPayment: parseFloat(finance.totalLoanPayment).toFixed(2),
          customerName: finance.customerId ? finance.customerId.name : 'Unknown',
        }));
        setFinances(transformedFinances);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching finance loans:', error);
        setError('Failed to load data');
        setLoading(false);
      });
  }, [apiURL]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleClickOpenEditDialog = () => {
    if (selectedRow && selectedRow._id) {
      navigate(`/customerloans/finances/${selectedRow._id}`);
      handleMenuClose();
    } else {
      console.error('No row selected or row does not have an id');
    }
  };

  const handleClickStockDialog = () => {
    if (selectedRow && selectedRow._id) {
      navigate(`/customerloans/finance-stocks/${selectedRow._id}`);
      handleMenuClose();
    } else {
      console.error('No row selected or row does not have an id');
    }
  };

  // Sorting logic
  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortFinances = (array, orderBy, orderDirection) => {
    return array.sort((a, b) => {
      const getValue = (obj, path) => path.split('.').reduce((o, p) => (o ? o[p] : ''), obj); // Safely access nested values

      const aValue = getValue(a, orderBy) ? getValue(a, orderBy).toString().toLowerCase() : ''; // Get value safely
      const bValue = getValue(b, orderBy) ? getValue(b, orderBy).toString().toLowerCase() : ''; // Get value safely

      if (orderDirection === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
      }
    });
  };

  const renderTableCell = (row, column) => {
    const fieldParts = column.field.split('.');
    let value = row;

    for (const part of fieldParts) {
      value = value[part];
    }

    return column.render ? column.render(value) : value;
  };

  const financeColumns = [
    { field: 'customerName', headerName: 'Customer' },
    { field: 'model.manufacturing', headerName: 'Manufacturing' },
    { field: 'model.model', headerName: 'Model' },
    { field: 'model.variants', headerName: 'Variants' },
    { field: 'model.color', headerName: 'Color' },
    { field: 'product', headerName: 'Product' },
    { field: 'customerCommitment', headerName: 'Commitment' },
    { field: 'condition', headerName: 'Condition' },
    { field: 'salePrice', headerName: 'Sale Price' },
    { field: 'deposit', headerName: 'Deposit' },
    { field: 'tenure', headerName: 'Tenure' },
    { field: 'financingAmount', headerName: 'Financing Amount' },
    {
      field: 'monthlyInstalmentAmount',
      headerName: 'Monthly Amount',
      render: (value) => value ? parseFloat(value).toFixed(2) : '0.00',
    },
    { field: 'totalLoanPayment', headerName: 'Total Payment' }
  ];

  // Filter finances based on search term (customer name)
  const filteredFinances = finances.filter((finance) =>
    finance.customerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort the filtered finances
  const sortedFinances = sortFinances(filteredFinances, orderBy, orderDirection);

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" padding="5px">
        <TextField
          label="Search by Customer Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
          style={{ width: '300px' }}
        />
      </Box>
      <TableContainer>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        ) : error ? (
          <div style={{ padding: '20px', textAlign: 'center', color: 'red' }}>
            {error}
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {financeColumns.map((column) => (
                  <TableCell key={column.field} sortDirection={orderBy === column.field ? orderDirection : false}>
                    <TableSortLabel
                      active={orderBy === column.field}
                      direction={orderBy === column.field ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest(column.field)}
                    >
                      {column.headerName}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedFinances.length > 0 ? sortedFinances.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={index}>
                  {financeColumns.map((column) => (
                    <TableCell key={column.field}>
                      {renderTableCell(row, column)}
                    </TableCell>
                  ))}
                  <TableCell>
                    <IconButton onClick={(e) => handleMenuOpen(e, row)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleClickOpenEditDialog}>Edit</MenuItem>
                      <MenuItem onClick={handleClickStockDialog}>Edit Stock</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              )) : (
                <TableRow>
                  <TableCell colSpan={financeColumns.length + 1} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {!loading && sortedFinances.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedFinances.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default FinanceLoan;
