import './order.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchData from '../../components/searchdata/SearchData';

const SearchAdvance = () => {
  return (
    <div className="order">
      <Sidebar />
      <div className="orderContainer">
        <Navbar />
        {/* <List />
         */}
         {/* <List  /> */}
         <SearchData />
      </div>
    </div>
  );
};

export default SearchAdvance;

