import React, { useEffect, useState } from 'react';
import { TableContainer, Paper, TablePagination, IconButton, Menu, MenuItem, Table, TableHead, TableBody, TableCell, TableRow, TableSortLabel } from '@mui/material';
import axios from 'axios';
import AddReferenceDialog from '../AddReferenceDialog'; // Adjust the path as per your file structure

const ReferenceLoan = () => {
  const [references, setReferences] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('customerName'); // Default sorting by 'customerName'
  const [orderDirection, setOrderDirection] = useState('asc'); // Default sorting direction
  const [openDialog, setOpenDialog] = useState(false);
  const [newReference, setNewReference] = useState({
    customerId: '',
    name: '',
    nricNo: '',
    contactNumber: '',
    relationship: ''
  });
  const [anchorEl, setAnchorEl] = useState(null); // For menu

  const apiURL = process.env.REACT_APP_API_URL;

  const fetchReferences = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.get(`${apiURL}/referencesloans/my-ref`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        }
      });
      const transformedReferences = response.data.map(item => ({
        ...item,
        customerName: item.customerId ? item.customerId.name : 'Unknown',
      }));
      setReferences(transformedReferences);
    } catch (error) {
      console.error('Error fetching references:', error);
    }
  };

  useEffect(() => {
    fetchReferences();
  }, [apiURL]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpenDialog = (customerId) => {
    setNewReference({ ...newReference, customerId });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReference({ ...newReference, [name]: value });
  };

  const handleFormSubmit = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      await axios.post(`${apiURL}/referencesloans`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        }
      }, newReference);
      setOpenDialog(false);
      fetchReferences(); // Fetch references again after adding new reference
    } catch (error) {
      console.error('Error creating reference:', error);
    }
  };

  const handleMenuClick = (event, customerId) => {
    setAnchorEl(event.currentTarget);
    setNewReference({ ...newReference, customerId });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Sorting function to sort data by the selected column and direction
  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Helper function to safely access nested values
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((value, key) => value?.[key], obj);
  };

  // Function to sort the data
  const sortData = (array, orderBy, orderDirection) => {
    return array.sort((a, b) => {
      const aValue = getNestedValue(a, orderBy) ? getNestedValue(a, orderBy).toString().toLowerCase() : '';
      const bValue = getNestedValue(b, orderBy) ? getNestedValue(b, orderBy).toString().toLowerCase() : '';
      if (orderDirection === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
      }
    });
  };

  const sortedReferences = sortData(references, orderBy, orderDirection);

  const referenceColumns = [
    { field: 'customerName', headerName: 'Customer' },
    { field: 'name', headerName: 'Name' },
    { field: 'nricNo', headerName: 'NRIC No' },
    { field: 'contactNumber', headerName: 'Contact Number' },
    { field: 'relationship', headerName: 'Relationship' },
  ];

  return (
    <div>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {referenceColumns.map((col) => (
                <TableCell
                  key={col.field}
                  sortDirection={orderBy === col.field ? orderDirection : false}
                >
                  <TableSortLabel
                    active={orderBy === col.field}
                    direction={orderBy === col.field ? orderDirection : 'asc'}
                    onClick={() => handleSortRequest(col.field)}
                  >
                    {col.headerName}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedReferences.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((reference) => (
              <TableRow key={reference._id}>
                {referenceColumns.map((col) => (
                  <TableCell key={col.field}>
                    {col.render ? col.render(reference[col.field]) : reference[col.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 40]}
          component="div"
          count={sortedReferences.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <AddReferenceDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onChange={handleInputChange}
        onSubmit={handleFormSubmit}
        newReference={newReference}
      />
    </div>
  );
};

export default ReferenceLoan;
