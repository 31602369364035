import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PaymentermsBalance = () => {
  const [paymentsByYear, setPaymentsByYear] = useState({});
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiURL = process.env.REACT_APP_API_URL; // Replace with your API URL

  useEffect(() => {
    const fetchPaymentsByYear = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${apiURL}/financeloans/state/fbalance`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`, // assuming token is stored in localStorage
          },
        });
        setPaymentsByYear(response.data.paymentsByYear || {});
      } catch (err) {
        setError('Failed to fetch payment terms balance data.');
        console.error('Error fetching payments by year:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentsByYear();
  }, [apiURL]);

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  const MonthlyPaymentsTable = ({ data }) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];

    return (
      <div className="table-container">
        <table className="monthlyPaymentsTable">
          <thead>
            <tr>
              <th>Month</th>
              <th>Monthly Installment</th>
              <th>Paid Amount</th>
              <th>Balance Amount</th>
              <th>Cumulative Paid</th>
            </tr>
          </thead>
          <tbody>
            {months.map((month) => (
              <tr key={month}>
                <td>{month}</td>
                <td>{data[month]?.monthlyInstalmentAmount?.toFixed(2) || '0.00'}</td>
                <td>{data[month]?.paidAmount?.toFixed(2) || '0.00'}</td>
                <td>{data[month]?.balanceAmount?.toFixed(2) || '0.00'}</td>
                <td>{data[month]?.cumulativePaid?.toFixed(2) || '0.00'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (isLoading) {
    return <div className="loading">Loading payment terms balance...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="paymenterms-balance">
      <h2 className="summary-title">Payment Terms Balance</h2>
      <div className="yearSelector">
        <label htmlFor="year">Select Year: </label>
        <select id="year" value={selectedYear} onChange={handleYearChange} className="yearDropdown">
          {Object.keys(paymentsByYear).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      {paymentsByYear[selectedYear] ? (
        <MonthlyPaymentsTable data={paymentsByYear[selectedYear]} />
      ) : (
        <div className="no-data">No data available for the selected year.</div>
      )}
    </div>
  );
};

export default PaymentermsBalance;
