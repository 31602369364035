import React, { useEffect, useState } from 'react';
import {
  Table, TableContainer, Paper, TablePagination, IconButton, Menu, MenuItem, TextField, TableHead, TableBody, TableCell, TableRow, Box, TableSortLabel
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AddReferenceDialog from '../AddReferenceDialog';
import AddScoreDialog from '../AddScoreDialog';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#037b7d',
    color: '#fff',
    zIndex: 10,
    '&:hover': {
      backgroundColor: '#2c9294',
    },
  },
});

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  return isNaN(date) ? 'Invalid date' : date.toLocaleDateString('en-US', options);
};

const CustomerLoan = () => {
  const [customerLoans, setCustomerLoans] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState(''); // Add search term state
  const [openDialog, setOpenDialog] = useState(false);
  const [openScoreDialog, setOpenScoreDialog] = useState(false);
  const [newReference, setNewReference] = useState({
    customerId: '',
    name: '',
    nricNo: '',
    contactNumber: '',
    relationship: ''
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [orderBy, setOrderBy] = useState('name'); // Default sorting by name
  const [orderDirection, setOrderDirection] = useState('asc'); // Default sorting direction

  const navigate = useNavigate(); // Initialize useNavigate
  const apiURL = process.env.REACT_APP_API_URL;
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpenScoreDialog = () => {
    setOpenScoreDialog(true);
    handleMenuClose();
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleClickOpenEditDialog = () => {
    if (selectedLoan) {
      navigate(`/customerloans/${selectedLoan._id}`); // Navigate to EditLoan route with loan ID
    }
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenScoreDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReference({ ...newReference, [name]: value });
  };

  const handleFormSubmit = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.post(`${apiURL}/referencesloans`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
          data: newReference
        });
      console.log('Reference created:', JSON.stringify(response.data, null, 2));
      setOpenDialog(false);
    } catch (error) {
      console.error('Error creating reference:', error);
    }
  };

  const handleMenuClick = (event, loan) => {
    setAnchorEl(event.currentTarget);
    setSelectedLoan(loan);
    setNewReference({
      customerId: loan._id,
      name: loan.name,
      nricNo: loan.nricNo,
      contactNumber: loan.mobileNumber,
      relationship: ''
    });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Sorting logic
  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortLoans = (array, orderBy, orderDirection) => {
    return array.sort((a, b) => {
      if (orderDirection === 'asc') {
        return a[orderBy] < b[orderBy] ? -1 : a[orderBy] > b[orderBy] ? 1 : 0;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : a[orderBy] < b[orderBy] ? 1 : 0;
      }
    });
  };

  useEffect(() => {
    const fetchCustomerLoans = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${apiURL}/customerloans`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${storedToken}`,
            }
          }
        );
        setCustomerLoans(response.data);
      } catch (error) {
        console.error('Error fetching customer loans:', error);
      }
    };

    fetchCustomerLoans();
  }, [apiURL]);

  // Filter customer loans based on the search term
  const filteredLoans = customerLoans.filter((loan) => {
    const name = loan.name?.toLowerCase() || '';
    const status = loan.status?.toLowerCase() || '';
    const nricNo = loan.nricNo?.toLowerCase() || '';

    return (
      name.includes(searchTerm.toLowerCase()) ||
      status.includes(searchTerm.toLowerCase()) ||
      nricNo.includes(searchTerm.toLowerCase())
    );
  });

  const sortedLoans = sortLoans(filteredLoans, orderBy, orderDirection);

  const customerLoanColumns = [
    { field: 'name', headerName: 'Name' },
    { field: 'status', headerName: 'Status' },
    { field: 'nricNo', headerName: 'NRIC No' },
    { field: 'mobileNumber', headerName: 'Mobile' },
    { field: 'email', headerName: 'Email' },
    { field: 'gender', headerName: 'Gender' },
    { field: 'maritalStatus', headerName: 'Marital Status' },
    { field: 'race', headerName: 'Race' },
    { field: 'address', headerName: 'Address' },
    
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      render: (loan) => formatDate(loan.updatedAt)
    }
  ];

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginLeft="20px" marginTop="5px" marginBottom="5px">
        <TextField
          label="Search by Name, Status, or NRIC No."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
          style={{ width: '400px' }} // Adjust width as necessary
        />
      </Box>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {customerLoanColumns.map((col) => (
                <TableCell key={col.field} sortDirection={orderBy === col.field ? orderDirection : false}>
                  <TableSortLabel
                    active={orderBy === col.field}
                    direction={orderBy === col.field ? orderDirection : 'asc'}
                    onClick={() => handleSortRequest(col.field)}
                  >
                    {col.headerName}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedLoans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((loan) => (
              <TableRow key={loan._id}>
                {customerLoanColumns.map((col) => (
                  <TableCell key={col.field} className={`${classes.normalText}`}>
                    {col.render ? col.render(loan) : loan[col.field]}
                  </TableCell>
                ))}
                <TableCell>
                  <IconButton onClick={(e) => handleMenuClick(e, loan)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedLoan?._id === loan._id}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleClickOpenDialog}>Add Reference</MenuItem>
                    <MenuItem onClick={handleClickOpenEditDialog}>Edit Loan</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 40]}
          component="div"
          count={sortedLoans.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <AddReferenceDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onChange={handleInputChange}
        onSubmit={handleFormSubmit}
        newReference={newReference}
      />
      <AddScoreDialog
        open={openScoreDialog}
        onClose={handleCloseDialog}
        onSubmit={handleCloseDialog}
        newReference={newReference}
      />
    </div>
  );
};

export default CustomerLoan;
